import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

const Excel = (props) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [barangay, setBarangay] = useState('');
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [civilStatus, setCivilStatus] = useState('');
  const [idStatus, setIdStatus] = useState('');
  const [memberFrom, setmemberFrom] = useState('');
  const [memberStatus, setMemberStatus] = useState('');
  const [memberTo, setmemberTo] = useState('');
  const [memberType, setMemberType] = useState('');
  const [chkAge, setChkAge] = useState(false);
  const [chkBarangay, setChkBarangay] = useState(false);
  const [chkCivilStatus, setChkCivilStatus] = useState(false);
  const [chkMemberStatus, setChkMemberStatus] = useState(false);
  const [chkMemberType, setchkMemberType] = useState(false);
  const [chkMembershipDate, setChkMembershipDate] = useState(false);
  const [chkIdStatus, setChkIdStatus] = useState(false);

  const { onFetchBarangays, barangays, filterRef } = props;

  const data = {
    barangay: barangay,
    from: from,
    to: to,
    civilStatus: civilStatus,
    idStatus: idStatus,
    memberFrom: memberFrom,
    memberTo: memberTo,
    memberType: memberType,
    memberStatus: memberStatus,
  };
  filterRef.current = data;

  useEffect(() => {
    onFetchBarangays();
  }, [onFetchBarangays]);

  useEffect(() => {
    if (barangays) {
      const brs = barangays.map((b) => {
        if (chkBarangay) {
          setBarangay(barangays[0].id);
        }
        return (
          <option key={b.id} value={b.id}>
            {b.name}
          </option>
        );
      });
      setBarangayOptions(brs);
    }
  }, [barangays, chkBarangay]);

  useEffect(() => {
    if (chkMemberStatus && memberStatus === '') {
      setMemberStatus(1);
    }
  }, [chkMemberStatus, memberStatus]);

  useEffect(() => {
    if (chkMemberType && memberType === '') {
      setMemberType(0);
    }
  }, [chkMemberType, memberType]);

  useEffect(() => {
    if (chkIdStatus && idStatus === '') {
      setIdStatus('1');
    }
  }, [chkIdStatus, idStatus]);

  const barangayChangeHandler = (e) => {
    setBarangay(e.target.value);
  };

  const civilStatusChangeHandler = (e) => {
    setCivilStatus(e.target.value);
  };

  return (
    <form>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="barangay"
      >
        Barangay
      </label>
      <div className="flex items-center">
        <input
          id="barangay-checkbox"
          type="checkbox"
          value={true}
          className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={() => setChkBarangay(!chkBarangay)}
          checked={chkBarangay}
        />
        <select
          className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="barangay"
          name="barangay"
          type="text"
          onChange={barangayChangeHandler}
          disabled={!chkBarangay}
        >
          {barangayOptions}
        </select>
      </div>
      <div className="flex flex-wrap mx-auto mb-6 py-4">
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor="age"
        >
          Age
        </label>
        <div className="flex items-center">
          <input
            id="age-checkbox"
            type="checkbox"
            value={true}
            className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={() => setChkAge(!chkAge)}
          />
          <div className="md:w-1/2 w-full px-1 mb-2">
            <input
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="ageFrom"
              name="ageFrom"
              type="number"
              onChange={(e) => setFrom(e.target.value)}
              disabled={!chkAge}
            />
          </div>
          <div className="md:w-1/2 w-full px-1 mb-2">
            <input
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="ageTo"
              name="ageTo"
              type="number"
              onChange={(e) => setTo(e.target.value)}
              disabled={!chkAge}
            />
          </div>
        </div>
      </div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="memberType"
      >
        Member Type
      </label>
      <div className="flex items-center">
        <input
          id="memberType-checkbox"
          type="checkbox"
          value={true}
          className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={() => setchkMemberType(!chkMemberType)}
          checked={chkMemberType}
        />
        <select
          className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="memberType"
          name="memberType"
          type="text"
          onChange={(e) => setMemberType(e.target.value)}
          disabled={!chkMemberType}
        >
          <option value={0} selected>
            Regular
          </option>
          <option value={1}>Premium</option>
        </select>
      </div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="membershipDate"
      >
        Member Since From
      </label>
      <div className="flex items-center">
        <input
          id="membershipDate-checkbox"
          type="checkbox"
          value={true}
          className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={() => setChkMembershipDate(!chkMembershipDate)}
        />
        <div className="md:w-1/2 w-full px-1 mb-6 md:mb-4">
          <input
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="memberFrom"
            name="memberFrom"
            type="date"
            onChange={(e) => setmemberFrom(e.target.value)}
            value={memberFrom}
            disabled={!chkMembershipDate}
          />
        </div>
        <div className="md:w-1/2 w-full px-1 mb-6 md:mb-4">
          <input
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="memberTo"
            name="memberTo"
            type="date"
            onChange={(e) => setmemberTo(e.target.value)}
            value={memberTo}
            disabled={!chkMembershipDate}
          />
        </div>
      </div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="civilStatus"
      >
        Civil Status
      </label>
      <div className="flex items-center">
        <input
          id="civilStatus-checkbox"
          type="checkbox"
          value={true}
          className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={() => setChkCivilStatus(!chkCivilStatus)}
          checked={chkCivilStatus}
        />
        <select
          className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="civilStatus"
          name="civilStatus"
          type="text"
          onChange={civilStatusChangeHandler}
          disabled={!chkCivilStatus}
        >
          <option value="SINGLE">SINGLE</option>
          <option value="MARRIED">MARRIED</option>
          <option value="WIDOWED">WIDOWED</option>
          <option value="DIVORCED">DIVORCED</option>
        </select>
      </div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4"
        htmlFor="membershipStatus"
      >
        Membership Status
      </label>
      <div className="flex items-center">
        <input
          id="membershipStatus-checkbox"
          type="checkbox"
          value={true}
          className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={() => setChkMemberStatus(!chkMemberStatus)}
          checked={chkMemberStatus}
        />
        <select
          className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="membershipStatus"
          name="membershipStatus"
          type="text"
          onChange={(e) => setMemberStatus(e.target.value)}
          defaultValue={memberStatus}
          disabled={!chkMemberStatus}
        >
          <option value={1} selected>
            Active
          </option>
          <option value={0}>In Active</option>
        </select>
      </div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="civilStatus"
      >
        ID Status
      </label>
      <div className="flex items-center">
        <input
          id="idStatus-checkbox"
          type="checkbox"
          value={true}
          className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={() => setChkIdStatus(!chkIdStatus)}
          checked={chkIdStatus}
        />
        <select
          className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="idStatus"
          name="idStatus"
          type="text"
          onChange={(e) => setIdStatus(e.target.value)}
          disabled={!chkIdStatus}
          defaultValue={idStatus}
        >
          <option value={1}>ISSUED</option>
          <option value={0}>NO ID</option>
        </select>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    barangays: state.barangays.barangays,
    occupations: state.occupations.occupations,
    loading: state.members.loading,
    error: state.members.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchBarangays: () => dispatch(actions.fetchAllBarangays()),
    onFetchOccupations: () => dispatch(actions.fetchAllOccupations()),
    onResetMembers: () => dispatch(actions.resetMembers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Excel);

import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import Chart from 'react-apexcharts';

import * as actions from '../../store/actions/index';

const Charts = (props) => {
  const [barangays, setBarangays] = useState([]);
  const [groupAge, setGroupAge] = useState([]);
  const [memberStatusCount, setMemberStatusCount] = useState([]);
  const {
    onCountBarangayStatus,
    onCountAgeGroup,
    onCountMemberStatus,
    barangayStatusCount,
    ageGroupCount,
    memberStatus,
  } = props;

  useEffect(() => {
    onCountMemberStatus();
  }, [onCountMemberStatus]);

  useEffect(() => {
    if (memberStatus) {
      setMemberStatusCount(memberStatus);
    }
  }, [memberStatus]);

  useEffect(() => {
    onCountBarangayStatus();
  }, [onCountBarangayStatus]);

  useEffect(() => {
    onCountAgeGroup();
  }, [onCountAgeGroup]);

  useEffect(() => {
    if (ageGroupCount?.length) {
      let ageGroup = [];
      ageGroup = ageGroupCount.map((a) => {
        return {
          age_group: a.age_group,
          member_count: a.member_count,
        };
      });
      setGroupAge(ageGroup);
    }
  }, [ageGroupCount]);

  useEffect(() => {
    if (barangayStatusCount?.length) {
      let brgy = [];
      brgy = barangayStatusCount.map((b) => {
        return {
          key: b?.sys_barangay?.id,
          name: b.name,
          active: b.active_members,
          inactive: b.inactive_members,
        };
      });
      setBarangays(brgy);
    }
  }, [barangayStatusCount]);

  const barangayNames = barangays.map(({ name }) => name);
  const barangayActiveMembers = barangays.map((barangay) =>
    parseInt(barangay.active)
  );
  const barangayInactiveMembers = barangays.map((barangay) =>
    parseInt(barangay.inactive)
  );

  const ageGroupLabel = groupAge.map(({ age_group }) => age_group);
  const ageGroupMemberCount = groupAge.map(({ member_count }) => member_count);

  const brgyOptions = {
    chart: {
      id: 'Brgy-group',
      type: 'bar',
    },
    xaxis: {
      categories: barangayNames,
    },
    dataLabels: {
      enabled: true, // enable data labels for the chart
      position: 'top',
      formatter: function (val) {
        return val.toFixed(0); // format data label to display integer value
      },
      style: {
        fontSize: '8px',
        colors: ['#000000'],
      },
    },
  };
  const brgySeries = [
    {
      name: 'Active',
      data: barangayActiveMembers,
    },
    {
      name: 'Inactive',
      data: barangayInactiveMembers,
    },
  ];

  const ageOption = {
    chart: {
      id: 'Age-group',
      type: 'bar',
    },
    xaxis: {
      categories: ageGroupLabel,
    },
    dataLabels: {
      enabled: true, // enable data labels for the chart
      position: 'top',
      formatter: function (val) {
        return val.toFixed(0); // format data label to display integer value
      },
    },
  };
  const ageSeries = [
    {
      name: 'Age',
      data: ageGroupMemberCount,
    },
  ];

  const statusOptions = {
    options: {
      chart: {
        id: 'status-group',
        type: 'pie',
      },
    },
    labels: ['Active', 'Inactive'],
  };

  let active = 0;
  let inactive = 0;
  if (memberStatusCount && memberStatusCount.length > 0) {
    memberStatusCount.map((data) => {
      if (data.status === 1) {
        active = data.count;
      }
      if (data.status === 0) {
        inactive = data.count;
      }
    });
  }

  return (
    <Fragment>
      <div className="grid lg:grid-cols-2 md:grid-rows-1 justify-center mx-auto mt-16">
        <div className="mx-auto my-auto">
          <h1 className="font-bold text-sky-600 text-3xl md:text-2xl sm:text-xs">
            Active/Inactive members
          </h1>
          <Chart
            options={statusOptions}
            series={[active, inactive]}
            type="pie"
            width="500"
          />
        </div>

        <div className="mx-auto my-auto">
          <h1 className="font-bold text-sky-600 text-3xl md:text-2xl sm:text-xs">
            Age group
          </h1>
          <Chart
            options={ageOption}
            series={ageSeries}
            type="bar"
            width={700}
            height={520}
          />
        </div>
      </div>
      <h1 className="font-bold text-sky-600 text-3xl md:text-2xl sm:text-xs">
        Barangay Status Count
      </h1>
      <Chart
        options={brgyOptions}
        series={brgySeries}
        type="bar"
        width={1500}
        height={520}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    barangayStatusCount: state.dashboard.barangayStatusCount,
    ageGroupCount: state.dashboard.ageGroupCount,
    memberStatus: state.dashboard.memberStatus,
    loading: state.dashboard.loading,
    error: state.dashboard.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCountBarangayStatus: () => dispatch(actions.countBarangayStatus()),
    onCountAgeGroup: () => dispatch(actions.countAgeGroup()),
    onCountMemberStatus: () => dispatch(actions.countMemberStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Charts);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

const Index = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const { onAuth, error, loading } = props;

  useEffect(() => {
    if (error) {
      setErrors({ error: error });
    }
    return () => {
      setErrors({});
    };
  }, [error]);

  const sumbitFormHandler = (e) => {
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };
    onAuth(data);
  };

  return (
    <div className="container mx-auto">
      <section className="h-screen">
        <div className="px-6 h-full text-gray-800">
          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
            <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="w-full"
                alt="authentication"
              />
            </div>
            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
              {errors.error ? (
                <div
                  className=" w-full p-4 mb-4 text-sm text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800"
                  role="alert"
                >
                  <span className="font-medium">Error!</span> {errors.error}
                </div>
              ) : (
                ''
              )}
              <form onSubmit={sumbitFormHandler}>
                <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                  <p className="text-center font-semibold text-xl mx-4 mb-0">
                    Mengal Women Admin
                  </p>
                </div>
                <div className="mb-6">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                  />
                </div>
                <div className="mb-6">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                </div>

                <div className="flex justify-between items-center mb-6">
                  <a href="#!" className="text-gray-800">
                    Forgot password?
                  </a>
                  <div className="text-center lg:text-left">
                    <button
                      type="submit"
                      className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      {loading ? 'Please Wait...' : 'Login'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (data) => dispatch(actions.auth(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

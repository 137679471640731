import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

import * as actions from "../../store/actions/index";

import Modal from "../UI/Modal/Index";
import Navigation from "../UI/Navigation/Index";
import Table from "../UI/Tables/Index";

const Index = (props) => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [registrationItems, setRegistrationItems] = useState([]);

  const {
    approved,
    declined,
    loading,
    onApproveRegistration,
    onDeclineRegistration,
    onFetchRegistrations,
    onResetRegistration,
    registrations,
  } = props;

  useEffect(() => {
    if (approved) {
      toast.success("Registration is Approved!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: () => {
          setOpen(false);
          onResetRegistration();
        },
      });
    }
  }, [approved, onResetRegistration]);

  useEffect(() => {
    if (declined) {
      toast.success("Registration is Declined!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: () => {
          setOpen(false);
          onResetRegistration();
        },
      });
    }
  }, [declined, onResetRegistration]);

  useEffect(() => {
    onFetchRegistrations();
  }, [onFetchRegistrations]);

  useEffect(() => {
    if (registrations) {
      setRegistrationItems(registrations);
    }
  }, [registrations]);

  const evaluateRegistrationHandler = useCallback(
    (
      id,
      lastname,
      firstname,
      middlename,
      nickname,
      birthdate,
      civilStatus,
      contact,
      occupation,
      skills,
      type,
      bene_lastname,
      bene_firstname,
      bene_middlename
    ) => {
      setOpen(true);
      const newDataItem = {
        ...data,
        id,
        lastname,
        firstname,
        middlename,
        nickname,
        birthdate,
        civilStatus,
        contact,
        occupation,
        skills,
        type,
        bene_lastname,
        bene_firstname,
        bene_middlename,
      };
      setData(newDataItem);
    },
    [data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Member Data",
        hideHeader: false,
        columns: [
          {
            Header: "Lastname",
            accessor: "lastname",
            Cell: ({ value, row }) => (
              <Link
                className={classNames(
                  row.original.status === "Approved" && "pointer-events-none"
                )}
                onClick={() =>
                  evaluateRegistrationHandler(
                    row.original.id,
                    row.original.lastname,
                    row.original.firstname,
                    row.original.middlename,
                    row.original.nickname,
                    row.original.birthdate,
                    row.original.civilStatus,
                    row.original.contact,
                    row.original.occupation,
                    row.original.skills,
                    row.original.type,
                    row.original.bene_lastname,
                    row.original.bene_firstname,
                    row.original.bene_middlename
                  )
                }
              >
                <b>{value}</b>
              </Link>
            ),
          },
          {
            Header: "FirstName",
            accessor: "firstname",
          },
          {
            Header: "MiddleName",
            accessor: "middlename",
          },
          {
            Header: "Contact",
            accessor: "contact",
          },
          {
            Header: "Membership Type",
            accessor: "type",
            Cell: ({ value }) => (
              <span
                className={classNames(
                  "text-xs font-semibold mr-2 px-2.5 py-0.5 rounded",
                  value === 1
                    ? "bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800"
                    : "bg-blue-100 text-blue-800  dark:bg-blue-200 dark:text-blue-800"
                )}
              >
                {value === 1 ? "Premium" : "Regular"}
              </span>
            ),
          },
        ],
      },
    ],
    [evaluateRegistrationHandler]
  );

  const approveRegistrationHandler = useCallback(
    (id) => {
      Swal.fire({
        title: "Approve Registration?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve Registration",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          onApproveRegistration({ id });
        }
      });
    },
    [onApproveRegistration]
  );

  const declineRegistrationHandler = useCallback(
    (id) => {
      Swal.fire({
        title: "Decline Registration?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Decline Registration",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          onDeclineRegistration({ id });
        }
      });
    },
    [onDeclineRegistration]
  );

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      {open && Object.keys(data).length > 0 && (
        <Modal
          title="Evaluate Registration"
          btnLabel="Approve Registration"
          btnDeclineLabel="Decline Registration"
          enter={() => approveRegistrationHandler(data.id)}
          cancel={() => setOpen(false)}
          decline={() => declineRegistrationHandler(data.id)}
          loading={loading}
        >
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Lastname: {data.lastname}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Firstname: {data.firstname}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Middlename: {data.middlename}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Nickname: {data.nickname}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Birthdate: {data.birthdate}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Civil Status: {data.civilStatus}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Contact: {data.contact}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Occupation: {data.occupation}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Skills/ Part Time Job: {data.skills}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Membership Type: {data.type === 1 ? "Premium" : "Regular"}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
            Beneficiary
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Lastname: {data.bene_lastname}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Firstname: {data.bene_firstname}
          </label>
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Middlename: {data.bene_middlename}
          </label>
        </Modal>
      )}
      <div className="container mt-6 w-full">
        <Table
          columns={columns}
          tableData={registrationItems}
          loading={loading}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    approved: state.registrations.approved,
    declined: state.registrations.declined,
    registrations: state.registrations.registrations,
    loading: state.registrations.loading,
    error: state.registrations.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onApproveRegistration: (id) => dispatch(actions.approveRegistration(id)),
    onDeclineRegistration: (id) => dispatch(actions.declineRegistration(id)),
    onFetchRegistrations: () => dispatch(actions.fetchRegistrations()),
    onResetRegistration: () => dispatch(actions.resetRegistration()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const uploadTitle = {
  fontSize: '20px',
  marginBottom: '10px',
};

const SignatureDropzone = (props) => {
  const [progressData, setProgressData] = useState(0);

  const { id, title, onUploadAttachment, progress, loading } = props;

  useEffect(() => {
    if (progress) {
      setProgressData(progress);
    }
  }, [progress]);

  const onDrop = useCallback(
    (file) => {
      const data = new FormData();
      data.append('file', file[0], id);
      data.append('type', 2);
      onUploadAttachment(data);
    },
    [id, onUploadAttachment]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    maxFiles: 2,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <div className="container">
      <div style={uploadTitle}>{title}</div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
      </div>
      {loading && (
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
          <div
            className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{ width: progressData + '%' }}
          >
            {Math.round(progressData) + '%'}
          </div>
        </div>
      )}
      {acceptedFileItems}
      {fileRejectionItems}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    progress: state.attachments.signatureProgress,
    loading: state.attachments.signatureLoading,
    error: state.attachments.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadAttachment: (data) => dispatch(actions.uploadSignature(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignatureDropzone);

import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const downloadAttachmentsStart = () => {
  return {
    type: actionTypes.DOWNLOAD_IMAGES_START,
  };
};

export const downloadAttachmentsSuccess = (attachments) => {
  return {
    type: actionTypes.DOWNLOAD_IMAGES_SUCCESS,
    attachments: attachments,
  };
};

export const downloadAttachmentsFailed = (error) => {
  return {
    type: actionTypes.DOWNLOAD_IMAGES_FAILED,
    error: error,
  };
};

export const downloadAttachments = (data) => {
  return (dispatch) => {
    const { id } = data;
    dispatch(downloadAttachmentsStart());
    axios
      .get(`attachments/download`, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(downloadAttachmentsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(downloadAttachmentsFailed(err));
      });
  };
};

export const fetchMemberPictureStart = () => {
  return {
    type: actionTypes.FETCH_MEMBER_PICTURE_START,
  };
};

export const fetchMemberPictureSuccess = (picture) => {
  return {
    type: actionTypes.FETCH_MEMBER_PICTURE_SUCCESS,
    picture: picture,
  };
};

export const fetchMemberPictureFailed = (error) => {
  return {
    type: actionTypes.FETCH_MEMBER_PICTURE_FAILED,
    error: error,
  };
};

export const fetchMemberPicture = (data) => {
  return (dispatch) => {
    const { id, type } = data;
    dispatch(fetchMemberPictureStart());
    axios
      .get(`attachments`, {
        params: {
          id: id,
          type: type,
        },
      })
      .then((response) => {
        dispatch(fetchMemberPictureSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchMemberPictureFailed(err));
      });
  };
};

export const fetchMemberSignatureStart = () => {
  return {
    type: actionTypes.FETCH_MEMBER_SIGNATURE_START,
  };
};

export const fetchMemberSignatureSuccess = (signature) => {
  return {
    type: actionTypes.FETCH_MEMBER_SIGNATURE_SUCCESS,
    signature: signature,
  };
};

export const fetchMemberSignatureFailed = (error) => {
  return {
    type: actionTypes.FETCH_MEMBER_SIGNATURE_FAILED,
    error: error,
  };
};

export const fetchMemberSignature = (data) => {
  return (dispatch) => {
    const { id, type } = data;
    dispatch(fetchMemberSignatureStart());
    axios
      .get(`attachments`, {
        params: {
          id: id,
          type: type,
        },
      })
      .then((response) => {
        dispatch(fetchMemberSignatureSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchMemberSignatureFailed(err));
      });
  };
};

export const uploadPhotoStart = (progress) => {
  return {
    type: actionTypes.UPLOAD_PHOTO_START,
    progress: progress,
  };
};

export const uploadPhotoSuccess = (created) => {
  return {
    type: actionTypes.UPLOAD_PHOTO_SUCCESS,
    created: created,
  };
};

export const uploadPhotoFailed = (error) => {
  return {
    type: actionTypes.UPLOAD_PHOTO_FAILED,
    error: error,
  };
};

export const uploadPhoto = (data) => {
  return (dispatch) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        let { progress } = 0;
        progress = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch(uploadPhotoStart(progress));
      },
    };
    axios
      .post(`attachments/create`, data, config)
      .then((response) => {
        dispatch(uploadPhotoSuccess(response.data.created));
      })
      .catch((err) => {
        dispatch(uploadPhotoFailed(err));
      });
  };
};

export const uploadSignatureStart = (progress) => {
  return {
    type: actionTypes.UPLOAD_SIGNATURE_START,
    progress: progress,
  };
};

export const uploadSignatureSuccess = (created) => {
  return {
    type: actionTypes.UPLOAD_SIGNATURE_SUCCESS,
    created: created,
  };
};

export const uploadSignatureFailed = (error) => {
  return {
    type: actionTypes.UPLOAD_SIGNATURE_FAILED,
    error: error,
  };
};

export const uploadSignature = (data) => {
  return (dispatch) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        let { progress } = 0;
        progress = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch(uploadSignatureStart(progress));
      },
    };
    axios
      .post(`attachments/create`, data, config)
      .then((response) => {
        dispatch(uploadSignatureSuccess(response.data.created));
      })
      .catch((err) => {
        dispatch(uploadSignatureFailed(err));
      });
  };
};

export const resetAttachments = () => {
  return {
    type: actionTypes.RESET_ATTACHMENTS,
  };
};

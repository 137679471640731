import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const statusItems = [
  { value: 'Active', name: 'Active' },
  { value: 'Deactivated', name: 'Deactivated' },
];

const Edit = (props) => {
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');

  const { onUpdateUser, onResetUsers, updated, loading } = props;

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state) {
      setStatus(location.state.status);
    }
  }, [location]);
  useEffect(() => {
    if (updated) {
      toast.success('User is Updated!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => {
        navigate('/users');
        onResetUsers();
      }, 2000);
    }
  }, [updated, navigate, onResetUsers]);

  const updateUserHandler = (e) => {
    e.preventDefault();
    const data = {
      id: new URLSearchParams(location.search).get('id'),
      password,
      status: status === 'Active' ? 1 : 0,
    };
    onUpdateUser(data);
  };

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <form onSubmit={updateUserHandler}>
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
            Edit User
          </h5>
          <div className="relative overflow-x-auto shadow-md bg-white">
            <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
              <div className="md:w-1/2 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div className="md:w-1/2 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="barangay"
                >
                  Status
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="status"
                  name="status"
                  type="text"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statusItems.map((data) => (
                    <option
                      value={data.value}
                      selected={status === data.name ? true : false}
                    >
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full px-4">
                <div className="flex justify-end">
                  <Button type="submit" className="mt-2" disabled={loading}>
                    {loading ? 'Please Wait...' : 'Update User'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    updated: state.users.updated,
    loading: state.users.loading,
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (data) => dispatch(actions.updateUser(data)),
    onResetUsers: () => dispatch(actions.resetUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import classNames from 'classnames';

import * as actions from '../../store/actions/index';

import Navigation from '../UI/Navigation/Index';
import Table from '../UI/Tables/Index';

const Index = (props) => {
  const [applicationItems, setApplicationItems] = useState([]);
  const { onFetchBenefitApplications, applications, loading } = props;

  useEffect(() => {
    onFetchBenefitApplications();
  }, [onFetchBenefitApplications]);

  useEffect(() => {
    if (applications) {
      setApplicationItems(applications);
    }
  }, [applications]);

  const columns = useMemo(
    () => [
      {
        Header: 'Benefit Data',
        hideHeader: false,
        columns: [
          {
            Header: 'ID',
            accessor: 'mid',
            Cell: ({ value, row }) => (
              <Link
                to={{
                  pathname: '/benefits/view',
                  search: `?id=${row.original.id}`,
                }}
                state={{ id: row.original.id }}
              >
                <b>{value}</b>
              </Link>
            ),
          },
          {
            Header: 'LastName',
            accessor: 'lastname',
          },
          {
            Header: 'FirstName',
            accessor: 'firstname',
          },
          {
            Header: 'MiddleName',
            accessor: 'middlename',
          },
          {
            Header: 'Application',
            accessor: 'application',
          },
          {
            Header: 'Date Applied',
            accessor: 'createdAt',
            Cell: ({ value }) => moment(value).format('MMM DD, YYYY'),
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <span
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 'APPROVED'
                    ? 'bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800'
                    : value === 'FOR EVALUATION'
                    ? 'bg-blue-100 text-blue-800  dark:bg-blue-200 dark:text-blue-800'
                    : 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800'
                )}
              >
                {value}
              </span>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      <Navigation />
      <div className="container mt-6 w-full">
        <Table
          columns={columns}
          tableData={applicationItems}
          loading={loading}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    applications: state.benefits.applications,
    loading: state.benefits.loading,
    error: state.benefits.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchBenefitApplications: () =>
      dispatch(actions.fetchBenefitApplications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

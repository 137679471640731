import { combineReducers } from 'redux';

import announcements from './announcements';
import articles from './articles';
import auth from './auth';
import attachments from './attachments';
import barangays from './barangays';
import benefits from './benefits';
import dashboard from './dashboard';
import members from './members';
import occupations from './occupations';
import payments from './payments';
import registrations from './registrations';
import users from './users';

export default combineReducers({
  announcements: announcements,
  articles: articles,
  auth: auth,
  attachments: attachments,
  barangays: barangays,
  benefits: benefits,
  dashboard: dashboard,
  members: members,
  occupations: occupations,
  payments: payments,
  registrations: registrations,
  users: users,
});

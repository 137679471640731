import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const createAnnouncementStart = () => {
  return {
    type: actionTypes.CREATE_ANNOUNCEMENT_START,
  };
};

export const createAnnouncementSuccess = (announcement) => {
  return {
    type: actionTypes.CREATE_ANNOUNCEMENT_SUCCESS,
    announcement: announcement,
  };
};

export const createAnnouncementFailed = (error) => {
  return {
    type: actionTypes.CREATE_ANNOUNCEMENT_FAILED,
    error: error,
  };
};

export const createAnnouncement = (data) => {
  return (dispatch) => {
    dispatch(createAnnouncementStart());
    axios
      .post(`announcements/create`, data)
      .then((response) => {
        dispatch(createAnnouncementSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(createAnnouncementFailed(err));
      });
  };
};

export const fetchAnnouncementsStart = () => {
  return {
    type: actionTypes.FETCH_ALL_ANNOUNCEMENTS_START,
  };
};

export const fetchAnnouncementsSuccess = (announcements) => {
  return {
    type: actionTypes.FETCH_ALL_ANNOUNCEMENTS_SUCCESS,
    announcements: announcements,
  };
};

export const fetchAnnouncementsFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_ANNOUNCEMENTS_FAILED,
    error: error,
  };
};

export const fetchAnnouncements = (data) => {
  return (dispatch) => {
    dispatch(fetchAnnouncementsStart());
    axios
      .get(`announcements`)
      .then((response) => {
        dispatch(fetchAnnouncementsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAnnouncementsFailed(err));
      });
  };
};

export const fetchOneAnnouncementStart = () => {
  return {
    type: actionTypes.FETCH_ONE_ANNOUNCEMENT_START,
  };
};

export const fetchOneAnnouncementSuccess = (announcement) => {
  return {
    type: actionTypes.FETCH_ONE_ANNOUNCEMENT_SUCCESS,
    announcement: announcement,
  };
};

export const fetchOneAnnouncementFailed = (error) => {
  return {
    type: actionTypes.FETCH_ONE_ANNOUNCEMENT_FAILED,
    error: error,
  };
};

export const fetchOneAnnouncement = (data) => {
  const { id } = data;
  return (dispatch) => {
    dispatch(fetchOneAnnouncementStart());
    axios
      .get(`announcements/find`, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(fetchOneAnnouncementSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchOneAnnouncementFailed(err));
      });
  };
};

export const updateAnnouncementStart = () => {
  return {
    type: actionTypes.UPDATE_ANNOUNCEMENT_START,
  };
};

export const updateAnnouncementSuccess = (updated) => {
  return {
    type: actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS,
    updated: updated,
  };
};

export const updateAnnouncementFailed = (error) => {
  return {
    type: actionTypes.UPDATE_ANNOUNCEMENT_FAILED,
    error: error,
  };
};

export const updateAnnouncement = (data) => {
  return (dispatch) => {
    dispatch(updateAnnouncementStart());
    axios
      .patch('announcements/update', data)
      .then((response) => {
        dispatch(updateAnnouncementSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(updateAnnouncementFailed(err));
      });
  };
};

export const unpublishAnnouncementStart = () => {
  return {
    type: actionTypes.UNPUBLISH_ANNOUNCEMENT_START,
  };
};

export const unpublishAnnouncementSuccess = (updated) => {
  return {
    type: actionTypes.UNPUBLISH_ANNOUNCEMENT_SUCCESS,
    updated: updated,
  };
};

export const unpublishAnnouncementFailed = (error) => {
  return {
    type: actionTypes.UNPUBLISH_ANNOUNCEMENT_FAILED,
    error: error,
  };
};

export const unpublishAnnouncement = (data) => {
  return (dispatch) => {
    dispatch(unpublishAnnouncementStart());
    axios
      .patch('announcements/unpublish', data)
      .then((response) => {
        dispatch(unpublishAnnouncementSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(unpublishAnnouncementFailed(err));
      });
  };
};

export const resetAnnouncements = () => {
  return {
    type: actionTypes.RESET_ANNOUNCEMENTS,
  };
};

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  article: null,
  articles: null,
  updated: false,
  error: null,
  loading: false,
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ARTICLE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.CREATE_ARTICLE_SUCCESS:
      return updateObject(state, {
        article: action.article,
        error: null,
        loading: false,
      });
    case actionTypes.CREATE_ARTICLE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ALL_ARTICLES_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_ARTICLES_SUCCESS:
      return updateObject(state, {
        articles: action.articles,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_ARTICLES_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ONE_ARTICLE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ONE_ARTICLE_SUCCESS:
      return updateObject(state, {
        article: action.article,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ONE_ARTICLE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.UPDATE_ARTICLE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UPDATE_ARTICLE_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UPDATE_ARTICLE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.UNPUBLISH_ARTICLE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UNPUBLISH_ARTICLE_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UNPUBLISH_ARTICLE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.RESET_ARTICLES:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default articles;

import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Filter from './Filter';

const Index = (props) => {
  const [hidden, setHidden] = useState(true);
  const [name, setName] = useState('');

  const { onFetchMembersByName } = props;

  const searchMemberHandler = (e) => {
    e.preventDefault();
    onFetchMembersByName({ name: name });
  };

  return (
    <Fragment>
      <form onSubmit={searchMemberHandler}>
        <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4 shadow-md">
          <div className="w-full px-3 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="lastname"
            >
              Name
            </label>
            <input
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="name"
              name="Name"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>

          <div className="w-full px-1 mb-12 md:mb-0">
            <div className="flex justify-end">
              <Button type="submit">Search</Button>
            </div>
          </div>
        </div>
      </form>
      <Button
        type="button"
        onClick={() => setHidden(!hidden)}
        className="h-8 px-4 py-1 text-sm font-medium"
        disabled={false}
      >
        {hidden ? 'Show Filters' : 'Hide Filters'}
      </Button>
      <Filter hidden={hidden} name={name} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMembersByName: (data) => dispatch(actions.fetchMembersByName(data)),
    onResetMembers: () => dispatch(actions.resetMembers()),
  };
};

export default connect(null, mapDispatchToProps)(Index);

import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const Index = (props) => {
  const [articleData, setArticleData] = useState('');

  const { articles, onFetchArticles, onResetArticles } = props;

  const navigate = useNavigate();

  useEffect(() => {
    onFetchArticles();
  }, [onFetchArticles]);

  const viewArticleHandler = (id) => {
    const path = {
      pathname: '/articles/view',
      search: createSearchParams({ id: id }).toString(),
    };
    return navigate(path);
  };

  const editArticleHandler = (id) => {
    const path = {
      pathname: '/articles/edit',
      search: createSearchParams({ id: id }).toString(),
    };
    return navigate(path);
  };

  useEffect(() => {
    if (articles) {
      const data = articles.map((a) => {
        return (
          <div
            className="p-6 w-full bg-white border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-6"
            key={a.id}
          >
            <p>
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {a.title}
              </h5>
            </p>
            <span className="mb-3 font-normal text-gray-700 dark:text-gray-400 opacity-70 text-sm italic">
              {moment(a.createdAt).format('MMMM DD, YYYY')}
            </span>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {a.short_description}
            </p>
            <Button
              onClick={() => viewArticleHandler(a.id)}
              className="inline-flex items-center py-2 px-3 text-sm font-medium text-center"
            >
              Read more
              <svg
                aria-hidden="true"
                className="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Button>
            <Button
              onClick={() => editArticleHandler(a.id)}
              className="inline-flex items-center py-2 px-3 ml-5 text-sm font-medium text-center"
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="ml-2 -mr-1 w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
            </Button>
          </div>
        );
      });
      setArticleData(data);
    }
  }, [articles]);

  const addNewButtonHandler = () => {
    onResetArticles();
    return navigate('/articles/create');
  };
  return (
    <Fragment>
      <Navigation />
      <div className="container mt-6 w-full">
        <Button onClick={addNewButtonHandler} className="mb-4">
          Add New
        </Button>
        {articleData}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    articles: state.articles.articles,
    loading: state.articles.loading,
    error: state.articles.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchArticles: () => dispatch(actions.fetchAllArticles()),
    onResetArticles: () => dispatch(actions.resetArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';
import Table from '../UI/Tables/Index';

const Index = (props) => {
  const [tableData, setTableData] = useState([]);
  const { onFetchAnnouncements, onResetAnnouncements, announcements, loading } =
    props;

  useEffect(() => {
    if (announcements) {
      setTableData(announcements);
    }
  }, [announcements]);

  useEffect(() => {
    onFetchAnnouncements();
  }, [onFetchAnnouncements]);

  const navigate = useNavigate();
  const addNewButtonHandler = () => {
    return navigate('/announcements/create');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Anouncement Data',
        hideHeader: false,
        columns: [
          {
            Header: '#',
            accessor: '',
            Cell: (row) => {
              return <div>{Number(row.row.id) + 1}</div>;
            },
          },
          {
            Header: 'Content',
            accessor: 'content',
          },
          {
            Header: 'Date Posted',
            accessor: 'createdAt',
            Cell: ({ value }) => moment(value).format('MMM DD, YYYY'),
          },
          {
            Header: 'Action',
            accessor: 'id',
            Cell: ({ value, row }) => (
              <Link
                to={{
                  pathname: '/announcements/edit',
                  search: `?id=${row.original.id}`,
                }}
                state={{ id: row.original.id }}
                onClick={() => onResetAnnouncements()}
              >
                <div className="flex items-center px-1">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    ></path>
                  </svg>
                  <b>Edit</b>
                </div>
              </Link>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      <Navigation />
      <div className="container mt-6 w-full">
        <Button onClick={addNewButtonHandler} className="mb-4">
          Add New
        </Button>
        <Table columns={columns} tableData={tableData} loading={loading} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    announcements: state.announcements.announcements,
    loading: state.announcements.loading,
    error: state.announcements.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchAnnouncements: () => dispatch(actions.fetchAnnouncements()),
    onResetAnnouncements: () => dispatch(actions.resetAnnouncements()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

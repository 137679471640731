import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  token: null,
  error: null,
  loading: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.AUTH_SUCCESS:
      return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
      });
    case actionTypes.AUTH_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, {
        token: null,
      });
    default:
      return state;
  }
};
export default auth;

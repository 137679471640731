import React from 'react';

const Index = (props) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="sm:flex sm:items-start">
              <div className="mt-1 mb-0 pt-2 px-5 text-center sm:mt-0 sm:ml-0 text-left">
                <h1
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  {props.title}
                </h1>
              </div>
            </div>
            <div className="relative flex py-2 items-center">
              <div className="flex-grow border-t border-orange-600 border-2"></div>
            </div>
            <div className="bg-white px-0 pt-0 pb-4 sm:p-5 sm:pt-2 sm:pb-4">
              <div className="mt-0">{props.children}</div>
            </div>
            <div className="btn-group bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={props.enter}
                disabled={props.loading}
              >
                {props.loading ? 'Please Wait...' : props.btnLabel}
              </button>
              {props.decline && (
                <button
                  type="button"
                  className="inline-flex w-full justify-center border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={props.decline}
                  disabled={props.loading}
                >
                  {props.loading
                    ? 'Please Wait...'
                    : props.btnDeclineLabel
                    ? props.btnDeclineLabel
                    : 'Decline Payment'}
                </button>
              )}
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={props.cancel}
                disabled={props.loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

import Announcements from './Components/Announcements/Index';
import AnnouncementsCreate from './Components/Announcements/Create';
import AnnouncementsEdit from './Components/Announcements/Edit';
import Articles from './Components/Articles/Index';
import ArticleCreate from './Components/Articles/Create';
import ArticleView from './Components/Articles/View';
import ArticleEdit from './Components/Articles/Edit';
import Attachments from './Components/Attachments/Index';
import Auth from './Components/Auth/Index';
import Home from './Components/Home/Index';
import BenefitIndex from './Components/Benefits/Index';
import BenefitView from './Components/Benefits/View';
import Logout from './Components/Auth/Logout';
import Members from './Components/Members/Index';
import MembersCreate from './Components/Members/Create';
import MembersEdit from './Components/Members/Edit';
import MembersView from './Components/Members/View';
import MemberUpload from './Components/Members/Upload';
import Payments from './Components/Payments/Index';
import PaymentResponse from './Components/Responses/PaymentResponse';
import RegistrationCreate from './Components/Registrations/Create';
import RegistrationIndex from './Components/Registrations/Index';
import UserIndex from './Components/Users/Index';
import UserCreate from './Components/Users/Create';
import UserEdit from './Components/Users/Edit';

function App(props) {
  const { onLogin, isAuthenticated } = props;
  useEffect(() => {
    onLogin();
  }, [onLogin]);

  let routes = '';
  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/attachment/:file" element={<Attachments />} />
        <Route exact path="/announcements" element={<Announcements />} />
        <Route path="/announcements/create" element={<AnnouncementsCreate />} />
        <Route path="/announcements/edit" element={<AnnouncementsEdit />} />
        <Route exact path="/members" element={<Members />} />
        <Route exact path="/members/view" element={<MembersView />} />
        <Route path="/members/create" element={<MembersCreate />} />
        <Route path="/members/edit" element={<MembersEdit />} />
        <Route path="/members/upload" element={<MemberUpload />} />
        <Route exact path="/payments" element={<Payments />} />
        <Route exact path="/articles" element={<Articles />} />
        <Route path="/articles/create" element={<ArticleCreate />} />
        <Route path="/articles/view" element={<ArticleView />} />
        <Route path="/articles/edit" element={<ArticleEdit />} />
        <Route exact path="/benefits" element={<BenefitIndex />} />
        <Route exact path="/benefits/view" element={<BenefitView />} />
        <Route exact path="/payment" element={<PaymentResponse />} />
        <Route exact path="/registrations" element={<RegistrationIndex />} />
        <Route exact path="/users" element={<UserIndex />} />
        <Route exact path="/user/create" element={<UserCreate />} />
        <Route exact path="/user/edit" element={<UserEdit />} />
        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route exact path="/" element={<Auth />} />
        <Route exact path="/attachment/:file" element={<Attachments />} />
        <Route exact path="/payment" element={<PaymentResponse />} />
        <Route exact path="/register" element={<RegistrationCreate />} />
      </Routes>
    );
  }
  return <BrowserRouter>{routes}</BrowserRouter>;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const createUserStart = () => {
  return {
    type: actionTypes.CREATE_USER_START,
  };
};

export const createUserSuccess = (user) => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    user: user,
  };
};

export const createUserFailed = (error) => {
  return {
    type: actionTypes.CREATE_USER_FAILED,
    error: error,
  };
};

export const createUser = (data) => {
  return (dispatch) => {
    dispatch(createUserStart());
    axios
      .post('users/create', data)
      .then((response) => {
        dispatch(createUserSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(createUserFailed(err));
      });
  };
};

export const fetchAllUsersStart = () => {
  return {
    type: actionTypes.FETCH_ALL_USERS_START,
  };
};

export const fetchAllUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_ALL_USERS_SUCCESS,
    users: users,
  };
};

export const fetchAllUsersFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_USERS_FAILED,
    error: error,
  };
};

export const fetchAllUsers = () => {
  return (dispatch) => {
    dispatch(fetchAllUsersStart());
    axios
      .get('users')
      .then((response) => {
        dispatch(fetchAllUsersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAllUsersFailed(err));
      });
  };
};

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START,
  };
};

export const updateUserSuccess = (updated) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    updated: updated,
  };
};

export const updateUserFailed = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAILED,
    error: error,
  };
};

export const updateUser = (data) => {
  return (dispatch) => {
    dispatch(updateUserStart());
    axios
      .patch('users/update', data)
      .then((response) => {
        dispatch(updateUserSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(updateUserFailed(err));
      });
  };
};

export const resetUserPasswordStart = () => {
  return {
    type: actionTypes.RESET_USER_PASSWORD_START,
  };
};

export const resetUserPasswordSuccess = (password) => {
  return {
    type: actionTypes.RESET_USER_PASSWORD_SUCCESS,
    password: password,
  };
};

export const resetUserPasswordFailed = (error) => {
  return {
    type: actionTypes.RESET_USER_PASSWORD_FAILED,
    error: error,
  };
};

export const resetUserPassword = (data) => {
  return (dispatch) => {
    dispatch(resetUserPasswordStart());
    axios
      .patch('users/reset', data)
      .then((response) => {
        dispatch(resetUserPasswordSuccess(response.data.password));
      })
      .catch((err) => {
        dispatch(resetUserPasswordFailed(err));
      });
  };
};

export const resetUsers = () => {
  return {
    type: actionTypes.RESET_USERS,
  };
};

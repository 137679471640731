import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import * as moment from 'moment';
import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const Edit = (props) => {
  // const [occupationOptions, setOccupationOptions] = useState([]);
  const [age, setAge] = useState(0);
  const [barangay, setBarangay] = useState('');
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [beneFirstname, setBeneFirstname] = useState('');
  const [beneLastname, setBeneLastname] = useState('');
  const [beneMiddlename, setBeneMiddlename] = useState('');
  const [beneRelationship, setBeneRelationship] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [civilStatus, setCivilStatus] = useState('');
  const [contact, setContact] = useState('');
  const [firstname, setFirstname] = useState('');
  const [id, setId] = useState('');
  const [lastname, setLastname] = useState('');
  const [memberType, setMemberType] = useState(0);
  const [middlename, setMiddlename] = useState('');
  const [nickname, setNickname] = useState('');
  const [noOfChild, setNoOfChild] = useState(0);
  const [occupation, setOccupation] = useState('');
  const [remarks, setRemarks] = useState('');
  const [skills, setSkills] = useState('');

  const location = useLocation().search;
  let navigate = useNavigate();

  const {
    onFetchOneMember,
    onFetchBarangays,
    // onFetchOccupations,
    onUpdateMember,
    onResetMembers,
    member,
    barangays,
    // occupations,
    updated,
    loading,
  } = props;

  useEffect(() => {
    if (location) {
      const param = new URLSearchParams(location).get('id');
      setId(param);
    }
  }, [location]);

  useEffect(() => {
    onFetchBarangays();
  }, [onFetchBarangays]);

  // useEffect(() => {
  //   onFetchOccupations();
  // }, [onFetchOccupations]);

  useEffect(() => {
    if (id) {
      onFetchOneMember({ id: id });
    }
  }, [onFetchOneMember, id]);

  useEffect(() => {
    if (barangays) {
      const brs = barangays.map((b) => {
        return (
          <option
            key={b.id}
            value={b.id}
            selected={barangay === b.id ? true : false}
          >
            {b.name}
          </option>
        );
      });
      setBarangayOptions(brs);
    }
  }, [barangays, barangay]);

  // useEffect(() => {
  //   if (occupations) {
  //     const ocs = occupations.map((o) => {
  //       return (
  //         <option
  //           key={o.id}
  //           value={o.id}
  //           selected={occupation === o.id ? true : false}
  //         >
  //           {o.name}
  //         </option>
  //       );
  //     });
  //     setOccupationOptions(ocs);
  //   }
  // }, [occupations, occupation]);

  useEffect(() => {
    if (member) {
      const m = member;
      setBarangay(m.barangayId);
      setBeneFirstname(m.beneficiary && m.beneficiary.firstname);
      setBeneLastname(m.beneficiary && m.beneficiary.lastname);
      setBeneMiddlename(m.beneficiary && m.beneficiary.middlename);
      setBeneRelationship(m.beneficiary && m.beneficiary.relationship);
      setBirthdate(m.birthdate);
      setCivilStatus(m.civilStatus);
      setContact(m.contact);
      setFirstname(m.firstname);
      setLastname(m.lastname);
      setMiddlename(m.middlename);
      setNickname(m.nickname);
      setNoOfChild(m.noOfChild);
      setOccupation(m.occupation);
      setRemarks(m.remarks);
      setSkills(m.skills);
      setMemberType(m.type);
    }
  }, [member]);

  useEffect(() => {
    if (updated) {
      toast.success('Member is Updated!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => {
        const path = {
          pathname: '/members/view',
          search: createSearchParams({ id: id }).toString(),
        };
        navigate(path);
      }, 2000);
      return () => {
        onResetMembers();
      };
    }
  }, [updated, navigate, onResetMembers, id]);

  const birthdateChangeHandler = (e) => {
    setBirthdate(e.target.value);
    if (moment(e.target.value).isValid()) {
      setAge(moment().diff(e.target.value, 'years'));
    }
  };

  useEffect(() => {
    if (moment(birthdate).isValid()) {
      setAge(moment().diff(birthdate, 'years'));
    }
  }, [birthdate]);

  const submitFormHandler = (e) => {
    e.preventDefault();
    const data = {
      barangay: barangay,
      beneFirstname: beneFirstname,
      beneLastname: beneLastname,
      beneMiddlename: beneMiddlename,
      beneRelationship: beneRelationship,
      birthdate: birthdate,
      civilStatus: civilStatus,
      contact: contact,
      firstname: firstname,
      id: id,
      lastname: lastname,
      middlename: middlename,
      nickname: nickname,
      noOfChild: noOfChild,
      occupation: occupation,
      remarks: remarks,
      skills: skills,
    };
    onUpdateMember(data);
  };

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <form onSubmit={submitFormHandler}>
          <div className="relative overflow-x-auto shadow-md bg-white">
            <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
              Personal Information
            </h5>
            <div className="flex flex-wrap -mx-auto mb-0 px-4 py-4">
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="lastname"
                >
                  Lastname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="lastname"
                >
                  Firstname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="middlename"
                >
                  Middlename
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="middlename"
                  name="middlename"
                  type="text"
                  value={middlename}
                  onChange={(e) => setMiddlename(e.target.value)}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="nickname"
                >
                  Nickname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="nickname"
                  name="nickname"
                  type="text"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="birthdate"
                >
                  Birthdate
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="birthdate"
                  name="birthdate"
                  type="date"
                  value={birthdate}
                  onChange={birthdateChangeHandler}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="birthdate"
                >
                  Age
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="age"
                  name="age"
                  type="number"
                  value={age}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="civilStatus"
                >
                  Civil Status
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="civilStatus"
                  name="civilStatus"
                  type="text"
                  onChange={(e) => setCivilStatus(e.target.value)}
                >
                  <option
                    key="1"
                    value="SINGLE"
                    selected={civilStatus === 'SINGLE' ? true : false}
                  >
                    SINGLE
                  </option>
                  <option
                    key="2"
                    value="MARRIED"
                    selected={civilStatus === 'MARRIED' ? true : false}
                  >
                    MARRIED
                  </option>
                  <option
                    key="3"
                    value="WIDOWED"
                    selected={civilStatus === 'WIDOWED' ? true : false}
                  >
                    WIDOWED
                  </option>
                  <option
                    key="4"
                    value="LEGALLY SEPERATED"
                    selected={
                      civilStatus === 'LEGALLY SEPERATED' ? true : false
                    }
                  >
                    LEGALLY SEPERATED
                  </option>
                  <option
                    key="5"
                    value="LIVE IN"
                    selected={civilStatus === 'LIVE IN' ? true : false}
                  >
                    LIVE IN
                  </option>
                </select>
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="contact"
                >
                  Contact
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="contact"
                  name="contact"
                  type="text"
                  maxLength={20}
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="occupation"
                >
                  Occupation
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="occupation"
                  name="occupation"
                  type="text"
                  onChange={(e) => setOccupation(e.target.value)}
                  value={occupation}
                />
                {/* {occupationOptions} */}
                {/* </select> */}
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="skills"
                >
                  Skills/ Part Time Job
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="skills"
                  name="skills"
                  type="text"
                  onChange={(e) => setSkills(e.target.value)}
                  value={skills}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="barangay"
                >
                  Barangay
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="barangay"
                  name="barangay"
                  type="text"
                  onChange={(e) => setBarangay(e.target.value)}
                >
                  {barangayOptions}
                </select>
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="memberType"
                >
                  Membership type
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="memberType"
                  name="memberType"
                  value={memberType}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="noOfChild"
                >
                  Number of Children
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="noOfChild"
                  name="noOfChild"
                  type="number"
                  onChange={(e) => setNoOfChild(e.target.value)}
                  value={noOfChild}
                />
              </div>
            </div>
            <div className="px-4">
              <label
                for="message"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Remarks
              </label>
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
            <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-0 md:mb-0 text-2xl font-bold">
              Beneficiary Information
            </h5>
            <div className="flex flex-wrap -mx-auto mb-6 px-4 py-4">
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="beneLastname"
                >
                  Lastname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="beneLastname"
                  name="beneLastname"
                  type="text"
                  onChange={(e) => setBeneLastname(e.target.value)}
                  value={beneLastname}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="beneFirstname"
                >
                  Firstname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="beneFirstname"
                  name="beneFirstname"
                  type="text"
                  onChange={(e) => setBeneFirstname(e.target.value)}
                  value={beneFirstname}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="beneMiddlename"
                >
                  Middlename
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="beneMiddlename"
                  name="beneMiddlename"
                  type="text"
                  onChange={(e) => setBeneMiddlename(e.target.value)}
                  value={beneMiddlename}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="relationship"
                >
                  Relationship
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="relationship"
                  name="relationship"
                  type="text"
                  onChange={(e) => setBeneRelationship(e.target.value)}
                  value={beneRelationship}
                />
              </div>
              <div className="w-full px-1 mb-12 md:mb-0">
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 mt-6"
                    disabled={loading}
                  >
                    {loading ? 'Please Wait...' : 'Update Member'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    barangays: state.barangays.barangays,
    member: state.members.member,
    created: state.members.created,
    occupations: state.occupations.occupations,
    updated: state.members.updated,
    loading: state.members.loading,
    error: state.members.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchOneMember: (data) => dispatch(actions.fetchOneMember(data)),
    onFetchBarangays: () => dispatch(actions.fetchAllBarangays()),
    onFetchOccupations: () => dispatch(actions.fetchAllOccupations()),
    onUpdateMember: (data) => dispatch(actions.updateMember(data)),
    onResetMembers: () => dispatch(actions.resetMembers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

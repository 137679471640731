import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  member: null,
  members: null,
  noIdMembers: null,
  updated: false,
  error: null,
  loading: false,
};

const members = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MEMBER_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.CREATE_MEMBER_SUCCESS:
      return updateObject(state, {
        member: action.member,
        error: null,
        loading: true,
      });
    case actionTypes.CREATE_MEMBER_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ALL_MEMBERS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_MEMBERS_SUCCESS:
      return updateObject(state, {
        members: action.members,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_MEMBERS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_MEMBERS_BY_NAME_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_MEMBERS_BY_NAME_SUCCESS:
      return updateObject(state, {
        members: action.members,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_MEMBERS_BY_NAME_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FILTER_MEMBERS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FILTER_MEMBERS_SUCCESS:
      return updateObject(state, {
        members: action.members,
        error: null,
        loading: false,
      });
    case actionTypes.FILTER_MEMBERS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ONE_MEMBER_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ONE_MEMBER_SUCCESS:
      return updateObject(state, {
        member: action.member,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ONE_MEMBER_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.UPDATE_MEMBER_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UPDATE_MEMBER_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UPDATE_MEMBER_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.UPDATE_ROLE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UPDATE_ROLE_SUCCESS:
      const index = state.members.findIndex(
        (data) => data.id === action.role.id
      );

      if (index === -1) {
        return state;
      }

      const updatedMembers = [...state.members];
      updatedMembers[index] = {
        ...state.members[index],
        role: action.role.role,
      };
      return updateObject(state, {
        updated: action.updated,
        members: updatedMembers,
        error: null,
        loading: false,
      });
    case actionTypes.UPDATE_ROLE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ALL_NO_ID_MEMBERS_START:
      return updateObject(state, {
        noIdMembers: null,
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_NO_ID_MEMBERS_SUCCESS:
      return updateObject(state, {
        noIdMembers: action.noIdMembers,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_NO_ID_MEMBERS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.RESET_MEMBERS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default members;

import React, { Fragment, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';
import ArticleDropzone from '../Common/ArticleDropzone';

const Create = (props) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');

  const { onCreateArticle, article, loading } = props;
  const navigate = useNavigate();

  const generatedImageHandler = (data) => {
    setImage(data);
  };

  useEffect(() => {
    if (article) {
      toast.success('Article is Created!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => {
        const path = {
          pathname: '/articles/view',
          search: createSearchParams({ id: article.id }).toString(),
        };
        navigate(path);
      }, 2000);
    }
  }, [article, navigate]);

  const submitArticleHandler = (e) => {
    e.preventDefault();
    image.append('title', title);
    image.append('content', content);
    onCreateArticle(image);
  };

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <form onSubmit={submitArticleHandler}>
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
            Create New Article
          </h5>
          <div className="relative overflow-x-auto shadow-md bg-white">
            <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
              <div className="w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="title"
                  name="title"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  required
                />
              </div>
              <div className="w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="content"
                >
                  Content
                </label>
                <textarea
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="content"
                  name="content"
                  type="text"
                  onChange={(e) => setContent(e.target.value)}
                  value={content}
                  required
                />
              </div>
              <div className="w-full px-3 mb-6 md:mb-4">
                <ArticleDropzone
                  generatedImage={(data) => generatedImageHandler(data)}
                />
              </div>
              <div className="w-full px-1 mb-12 md:mb-0">
                <div className="flex justify-end">
                  <Button type="submit" className="mt-6" disabled={loading}>
                    {loading ? 'Saving article...' : 'Submit'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.articles.article,
    loading: state.articles.loading,
    error: state.articles.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateArticle: (data) => dispatch(actions.createArticle(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);

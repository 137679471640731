import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  application: null,
  applications: null,
  attachments: null,
  firebaseId: null,
  updated: false,
  error: null,
  loading: false,
};

const benefits = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BENEFIT_APPLICATIONS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_BENEFIT_APPLICATIONS_SUCCESS:
      return updateObject(state, {
        applications: action.applications,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_BENEFIT_APPLICATIONS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ONE_BENEFIT_APPLICATIONS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ONE_BENEFIT_APPLICATIONS_SUCCESS:
      return updateObject(state, {
        application: action.application,
        attachments: action.attachments,
        firebaseId: action.firebaseId,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ONE_BENEFIT_APPLICATIONS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.UPDATE_BENEFIT_APPLICATION_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UPDATE_BENEFIT_APPLICATION_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UPDATE_BENEFIT_APPLICATION_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.RESET_BENEFITS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default benefits;

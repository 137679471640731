import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import Navigation from '../UI/Navigation/Index';
import Charts from './Charts';

const Index = (props) => {
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalInActive, setTotalInActive] = useState(0);
  const [totalForEvaluation, setTotalForEvaluation] = useState(0);
  const { onCountAllMembers, onCountMemberStatus, members, memberStatus } =
    props;

  useEffect(() => {
    onCountAllMembers();
  }, [onCountAllMembers]);

  useEffect(() => {
    onCountMemberStatus();
  }, [onCountMemberStatus]);

  useEffect(() => {
    if (members) {
      setTotalMembers(members);
    }
  }, [members]);

  useEffect(() => {
    if (memberStatus) {
      memberStatus.forEach((el) => {
        if (el.status === 1) {
          setTotalActive(el.count);
        } else if (el.status === 0) {
          setTotalInActive(el.count);
        } else {
          setTotalForEvaluation(el.count);
        }
      });
    }
  }, [memberStatus]);

  return (
    <Fragment>
      <Navigation />
      <div className="container mt-4 w-full">
        <div className="grid sm:w-full grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 content-start">
          <div className="max-w-sm">
            <div className="block p-6 bg-white border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold dark:text-white">
                  {totalMembers.toLocaleString()}
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  Total Members
                </dd>
              </div>
            </div>
          </div>
          <div>
            <div className="block max-w-sm p-6 bg-white border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold dark:text-white">
                  {totalActive.toLocaleString()}
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  Active Members
                </dd>
              </div>
            </div>
          </div>
          <div>
            <div className="block max-w-sm p-6 bg-white border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold dark:text-white">
                  {totalInActive.toLocaleString()}
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  In Active Members
                </dd>
              </div>
            </div>
          </div>
          <div>
            <Link
              to="/payments"
              className="block max-w-sm p-6 bg-white border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold dark:text-white">
                  {totalForEvaluation.toLocaleString()}
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  Payment For Evaluation
                </dd>
              </div>
            </Link>
          </div>
        </div>
        <div>
          <Charts />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    members: state.dashboard.totalMembers,
    memberStatus: state.dashboard.memberStatus,
    loading: state.dashboard.loading,
    error: state.dashboard.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCountAllMembers: () => dispatch(actions.countAllMembers()),
    onCountMemberStatus: () => dispatch(actions.countMemberStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  password: null,
  updated: false,
  user: null,
  users: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.CREATE_USER_SUCCESS:
      return updateObject(state, {
        user: action.user,
        error: null,
        loading: false,
      });
    case actionTypes.CREATE_USER_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.UPDATE_USER_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UPDATE_USER_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ALL_USERS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_USERS_SUCCESS:
      return updateObject(state, {
        users: action.users,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_USERS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.RESET_USER_PASSWORD_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.RESET_USER_PASSWORD_SUCCESS:
      return updateObject(state, {
        password: action.password,
        error: null,
        loading: false,
      });
    case actionTypes.RESET_USER_PASSWORD_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.RESET_USERS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default users;

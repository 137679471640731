import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  approved: false,
  declined: false,
  data: null,
  registrations: null,
  error: null,
  loading: false,
};

const registrations = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_REGISTRATION_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.APPROVE_REGISTRATION_SUCCESS:
      return updateObject(state, {
        approved: action.approved,
        registrations: state.registrations?.filter((el) => {
          return el.id !== action.id;
        }),
        error: null,
        loading: true,
      });
    case actionTypes.APPROVE_REGISTRATION_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.DECLINE_REGISTRATION_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.DECLINE_REGISTRATION_SUCCESS:
      return updateObject(state, {
        declined: action.declined,
        registrations: state.registrations?.filter((el) => {
          return el.id !== action.id;
        }),
        error: null,
        loading: true,
      });
    case actionTypes.DECLINE_REGISTRATION_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.REGISTER_MEMBER_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.REGISTER_MEMBER_SUCCESS:
      return updateObject(state, {
        data: action.registration,
        error: null,
        loading: false,
      });
    case actionTypes.REGISTER_MEMBER_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_REGISTRATIONS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_REGISTRATIONS_SUCCESS:
      return updateObject(state, {
        registrations: action.registrations,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_REGISTRATIONS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.RESET_REGISTRATION:
      return updateObject(state, {
        approved: false,
        declined: false,
        loading: false,
      });
    default:
      return state;
  }
};

export default registrations;

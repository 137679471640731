import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import axios from '../../axios';
import Button from '../UI/Buttons/Index';

const Index = () => {
  const params = useParams();
  const [attachment, setAttachment] = useState('');

  const downloadHandler = () => {
    saveAs(attachment, params.file);
  };
  useEffect(() => {
    axios
      .get('/attachments/public', {
        params: { file: params.file },
      })
      .then((res) => {
        setAttachment(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [attachment]);

  return (
    <div className="container mt-6 w-full">
      <Button className="mb-6" onClick={downloadHandler}>
        Download
      </Button>
      <img className="object-scale-down h-100 w-200" src={attachment} />
    </div>
  );
};

export default Index;

import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const createArticleStart = () => {
  return {
    type: actionTypes.CREATE_ARTICLE_START,
  };
};

export const createArticleSuccess = (article) => {
  return {
    type: actionTypes.CREATE_ARTICLE_SUCCESS,
    article: article,
  };
};

export const createArticleFailed = (error) => {
  return {
    type: actionTypes.CREATE_ARTICLE_FAILED,
    error: error,
  };
};

export const createArticle = (data) => {
  return (dispatch) => {
    dispatch(createArticleStart());
    axios
      .post(`articles/create`, data)
      .then((response) => {
        dispatch(createArticleSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(createArticleFailed(err));
      });
  };
};

export const fetchAllArticlesStart = () => {
  return {
    type: actionTypes.FETCH_ALL_ARTICLES_START,
  };
};

export const fetchAllArticlesSuccess = (articles) => {
  return {
    type: actionTypes.FETCH_ALL_ARTICLES_SUCCESS,
    articles: articles,
  };
};

export const fetchAllArticlesFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_ARTICLES_FAILED,
    error: error,
  };
};

export const fetchAllArticles = () => {
  return (dispatch) => {
    dispatch(fetchAllArticlesStart());
    axios
      .get(`articles`)
      .then((response) => {
        dispatch(fetchAllArticlesSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAllArticlesFailed(err));
      });
  };
};

export const fetchOneArticleStart = () => {
  return {
    type: actionTypes.FETCH_ONE_ARTICLE_START,
  };
};

export const fetchOneArticleSuccess = (article) => {
  return {
    type: actionTypes.FETCH_ONE_ARTICLE_SUCCESS,
    article: article,
  };
};

export const fetchOneArticleFailed = (error) => {
  return {
    type: actionTypes.FETCH_ONE_ARTICLE_FAILED,
    error: error,
  };
};

export const fetchOneArticle = (data) => {
  const { id } = data;
  return (dispatch) => {
    dispatch(fetchOneArticleStart());
    axios
      .get(`articles/find`, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(fetchOneArticleSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchOneArticleFailed(err));
      });
  };
};

export const updateArticleStart = () => {
  return {
    type: actionTypes.UPDATE_ARTICLE_START,
  };
};

export const updateArticleSuccess = (updated) => {
  return {
    type: actionTypes.UPDATE_ARTICLE_SUCCESS,
    updated: updated,
  };
};

export const updateArticleFailed = (error) => {
  return {
    type: actionTypes.UPDATE_ARTICLE_FAILED,
    error: error,
  };
};

export const updateArticle = (data) => {
  return (dispatch) => {
    dispatch(updateArticleStart());
    axios
      .patch('articles/update', data)
      .then((response) => {
        dispatch(updateArticleSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(updateArticleFailed(err));
      });
  };
};

export const unpublishArticleStart = () => {
  return {
    type: actionTypes.UNPUBLISH_ARTICLE_START,
  };
};

export const unpublishArticleSuccess = (updated) => {
  return {
    type: actionTypes.UNPUBLISH_ARTICLE_SUCCESS,
    updated: updated,
  };
};

export const unpublishArticleFailed = (error) => {
  return {
    type: actionTypes.UNPUBLISH_ARTICLE_FAILED,
    error: error,
  };
};

export const unpublishArticle = (data) => {
  return (dispatch) => {
    dispatch(unpublishArticleStart());
    axios
      .patch('articles/unpublish', data)
      .then((response) => {
        dispatch(unpublishArticleSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(unpublishArticleFailed(err));
      });
  };
};

export const resetArticles = () => {
  return {
    type: actionTypes.RESET_ARTICLES,
  };
};

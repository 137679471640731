import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  barangays: null,
  error: null,
  loading: false,
};

const barangays = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_BARANGAYS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_BARANGAYS_SUCCESS:
      return updateObject(state, {
        barangays: action.barangays,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_BARANGAYS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    default:
      return state;
  }
};

export default barangays;

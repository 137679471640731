import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import Navigation from '../UI/Navigation/Index';

const View = (props) => {
  const [id, setId] = useState('');
  const [content, setContent] = useState('');

  const { article, onFetchArticle } = props;
  const location = useLocation().search;

  useEffect(() => {
    if (location) {
      const param = new URLSearchParams(location).get('id');
      setId(param);
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      onFetchArticle({ id: id });
    }
  }, [onFetchArticle, id]);

  useEffect(() => {
    if (article) {
      const data = (
        <div className="w-full bg-white border rounded border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <img
            className="rounded-t-lg"
            src={article.image}
            alt={article.id + ' Article Image'}
          />
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {article.title}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {article.description}
            </p>
          </div>
        </div>
      );
      setContent(data);
    }
  }, [article]);

  return (
    <Fragment>
      <Navigation />
      <div className="container mt-6 w-full">{content}</div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.articles.article,
    loading: state.articles.loading,
    error: state.articles.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchArticle: (data) => dispatch(actions.fetchOneArticle(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

import React from 'react';
import classNames from 'classnames';

const Index = ({ className, onClick, disabled, children, type }) => {
  return (
    <button
      type={type}
      className={classNames(
        className,
        'bg-white hover:bg-gray-100 text-gray-800 font-semibold px-4 border border-gray-400 shadow dark:font-semibold dark:text-white dark:hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 p-1',
        disabled
          ? 'border-gray-100 bg-gray-100 hover-bg-gray-500 text-gray-400 dark:border-gray-100 dark:bg-gray-500 dark:hover:bg-gray-500'
          : ''
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Index;

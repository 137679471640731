import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Swal from 'sweetalert2';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const Edit = (props) => {
  const [id, setId] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [articleDescription, setArticleDescription] = useState('');
  const [articleImage, setArticleImage] = useState('');

  const {
    article,
    loading,
    updated,
    onFetchArticle,
    onUpdateArticle,
    onUnpublishArticle,
    onResetArticle,
  } = props;

  const location = useLocation().search;
  let navigate = useNavigate();

  const handleTextTitleChange = (event) => {
    setArticleTitle(event.target.value);
  };
  const handleTextDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setArticleDescription(newDescription);
  };

  useEffect(() => {
    if (updated) {
      toast.success('Changes Saved! Please wait..', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      onResetArticle();
      setTimeout(() => {
        navigate('/articles');
      }, 2000);
    }
  }, [updated, onResetArticle, navigate]);

  useEffect(() => {
    if (location) {
      const param = new URLSearchParams(location).get('id');
      setId(param);
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      onFetchArticle({ id: id });
    }
  }, [onFetchArticle, id]);

  useEffect(() => {
    if (article) {
      setArticleTitle(article.title);
      setArticleDescription(article.description);
      setArticleImage(article.image);
    }
  }, [article]);

  const saveButtonHandler = (id) => {
    Swal.fire({
      title: 'Are you sure to save the changes on the TITLE and DESCRIPTION?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save changes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
          title: articleTitle,
          description: articleDescription,
        };
        onUpdateArticle(data);
      }
    });
  };

  const deleteButtonHandler = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this article?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete article',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
        };
        onUnpublishArticle(data);
      }
    });
  };

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <div className="w-full bg-white border rounded border-gray-200 shadow-md dark:bg-white dark:border-gray-700">
          <img
            className="rounded-t-lg"
            src={articleImage}
            alt={id + ' Article Image'}
          />
          <div className="p-5">
            <div className="mb-4">
              <label className="block mb-1 text-lg font-semibold text-gray-800 dark:text-gray-800">
                Article Title
              </label>
              <textarea
                className="w-full py-2 px-3 text-lg text-gray-900 bg-slate-200 dark:text-black rounded-md"
                onChange={handleTextTitleChange}
                placeholder="Enter the article title..."
                value={articleTitle}
              />
            </div>
            <div>
              <label className="block mb-1 text-lg font-semibold text-gray-800 dark:text-gray-800">
                Article Description
              </label>
              <textarea
                className="w-full py-2 px-3 text-base text-black bg-slate-200 dark:text-black rounded-md"
                onChange={handleTextDescriptionChange}
                placeholder="Enter a brief description of the article..."
                value={articleDescription}
              />
            </div>
            <div className="btn-group mt-4 sm:flex sm:flex-row-reverse sm:px-6">
              <Button
                className="px-4"
                onClick={() => saveButtonHandler(id)}
                disabled={Object.keys(articleDescription).length < 0 || loading}
              >
                Save changes
              </Button>
              <Button
                className="mr-4 px-4"
                onClick={() => deleteButtonHandler(id)}
                disabled={Object.keys(articleDescription).length < 0 || loading}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.articles.article,
    updated: state.articles.updated,
    loading: state.articles.loading,
    error: state.articles.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchArticle: (data) => dispatch(actions.fetchOneArticle(data)),
    onUpdateArticle: (data) => dispatch(actions.updateArticle(data)),
    onResetArticle: () => dispatch(actions.resetArticles()),
    onUnpublishArticle: (data) => dispatch(actions.unpublishArticle(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../store/actions/index';

const Logout = (props) => {
  const { onLogout } = props;
  const navigate = useNavigate();

  useEffect(() => {
    onLogout();
    return navigate('/');
  }, [onLogout, navigate]);
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(null, mapDispatchToProps)(Logout);

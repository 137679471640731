import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const countAllMembersStart = () => {
  return {
    type: actionTypes.COUNT_ALL_MEMBERS_START,
  };
};

export const countAllMembersSuccess = (totalMembers) => {
  return {
    type: actionTypes.COUNT_ALL_MEMBERS_SUCCESS,
    totalMembers: totalMembers,
  };
};

export const countAllMembersFailed = (error) => {
  return {
    type: actionTypes.COUNT_ALL_MEMBERS_FAILED,
    error: error,
  };
};

export const countAllMembers = () => {
  return (dispatch) => {
    dispatch(countAllMembersStart());
    axios
      .get('dashboard/members/all')
      .then((response) => {
        dispatch(countAllMembersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(countAllMembersFailed(err));
      });
  };
};

export const countMemberStatusStart = () => {
  return {
    type: actionTypes.COUNT_MEMBER_STATUS_START,
  };
};

export const countMemberStatusSuccess = (memberStatus) => {
  return {
    type: actionTypes.COUNT_MEMBER_STATUS_SUCCESS,
    memberStatus: memberStatus,
  };
};

export const countMemberStatusFailed = (error) => {
  return {
    type: actionTypes.COUNT_MEMBER_STATUS_FAILED,
    error: error,
  };
};

export const countMemberStatus = () => {
  return (dispatch) => {
    dispatch(countAllMembersStart());
    axios
      .get('dashboard/members/status')
      .then((response) => {
        dispatch(countMemberStatusSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(countMemberStatusFailed(err));
      });
  };
};

export const countBarangayStatusStart = () => {
  return {
    type: actionTypes.COUNT_BARANGAY_STATUS_START,
  };
};

export const countBarangayStatusSuccess = (barangayStatusCount) => {
  return {
    type: actionTypes.COUNT_BARANGAY_STATUS_SUCCESS,
    barangayStatusCount: barangayStatusCount,
  };
};

export const countBarangayStatusFailed = (error) => {
  return {
    type: actionTypes.COUNT_BARANGAY_STATUS_FAILED,
    error: error,
  };
};

export const countBarangayStatus = () => {
  return (dispatch) => {
    dispatch(countBarangayStatusStart());
    axios
      .get('dashboard/members/count-status-by-barangay')
      .then((response) => {
        dispatch(countBarangayStatusSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(countBarangayStatusFailed(err));
      });
  };
};

export const countAgeGroupStart = () => {
  return {
    type: actionTypes.COUNT_AGE_GROUP_START,
  };
};

export const countAgeGroupSuccess = (ageGroupCount) => {
  return {
    type: actionTypes.COUNT_AGE_GROUP_SUCCESS,
    ageGroupCount: ageGroupCount,
  };
};

export const countAgeGroupFailed = (error) => {
  return {
    type: actionTypes.COUNT_AGE_GROUP_FAILED,
    error: error,
  };
};

export const countAgeGroup = () => {
  return (dispatch) => {
    dispatch(countAgeGroupStart());
    axios
      .get('dashboard/members/count-age-group')
      .then((response) => {
        dispatch(countAgeGroupSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(countAgeGroupFailed(err));
      });
  };
};

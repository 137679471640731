export const APPROVE_REGISTRATION_START = 'APPROVE_REGISTRATION_START';
export const APPROVE_REGISTRATION_SUCCESS = 'APPROVE_REGISTRATION_SUCCESS';
export const APPROVE_REGISTRATION_FAILED = 'APPROVE_REGISTRATION_FAILED';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const CREATE_ANNOUNCEMENT_START = 'CREATE_ANNOUNCEMENT_START';
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS';
export const CREATE_ANNOUNCEMENT_FAILED = 'CREATE_ANNOUNCEMENT_FAILED';

export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAILED = 'CREATE_ARTICLE_FAILED';

export const CREATE_MEMBER_START = 'CREATE_MEMBER_START';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILED = 'CREATE_MEMBER_FAILED';

export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const COUNT_ALL_MEMBERS_START = 'COUNT_ALL_MEMBERS_START';
export const COUNT_ALL_MEMBERS_SUCCESS = 'COUNT_ALL_MEMBERS_SUCCESS';
export const COUNT_ALL_MEMBERS_FAILED = 'COUNT_ALL_MEMBERS_FAILED';

export const DECLINE_REGISTRATION_START = 'DECLINE_REGISTRATION_START';
export const DECLINE_REGISTRATION_SUCCESS = 'DECLINE_REGISTRATION_SUCCESS';
export const DECLINE_REGISTRATION_FAILED = 'DECLINE_REGISTRATION_FAILED';

export const DOWNLOAD_IMAGES_START = 'DOWNLOAD_IMAGES_START';
export const DOWNLOAD_IMAGES_SUCCESS = 'DOWNLOAD_IMAGES_SUCCESS';
export const DOWNLOAD_IMAGES_FAILED = 'DOWNLOAD_IMAGES_FAILED';

export const COUNT_MEMBER_STATUS_START = 'COUNT_MEMBER_STATUS_START';
export const COUNT_MEMBER_STATUS_SUCCESS = 'COUNT_MEMBER_STATUS_SUCCESS';
export const COUNT_MEMBER_STATUS_FAILED = 'COUNT_MEMBER_STATUS_FAILED';

export const EVALUATE_PAYMENT_START = 'EVALUATE_PAYMENT_START';
export const EVALUATE_PAYMENT_SUCCESS = 'EVALUATE_PAYMENT_SUCCESS';
export const EVALUATE_PAYMENT_FAILED = 'EVALUATE_PAYMENT_FAILED';

export const FETCH_ALL_ANNOUNCEMENTS_START = 'FETCH_ALL_ANNOUNCEMENTS_START';
export const FETCH_ALL_ANNOUNCEMENTS_SUCCESS =
  'FETCH_ALL_ANNOUNCEMENTS_SUCCESS';
export const FETCH_ALL_ANNOUNCEMENTS_FAILED = 'FETCH_ALL_ANNOUNCEMENTS_FAILED';

export const FETCH_ONE_ANNOUNCEMENT_START = 'FETCH_ONE_ANNOUNCEMENT_START';
export const FETCH_ONE_ANNOUNCEMENT_SUCCESS = 'FETCH_ONE_ANNOUNCEMENT_SUCCESS';
export const FETCH_ONE_ANNOUNCEMENT_FAILED = 'FETCH_ONE_ANNOUNCEMENT_FAILED';

export const FETCH_ALL_ARTICLES_START = 'FETCH_ALL_ARTICLES_START';
export const FETCH_ALL_ARTICLES_SUCCESS = 'FETCH_ALL_ARTICLES_SUCCESS';
export const FETCH_ALL_ARTICLES_FAILED = 'FETCH_ALL_ARTICLES_FAILED';

export const FETCH_ALL_BARANGAYS_START = 'FETCH_ALL_BARANGAYS_START';
export const FETCH_ALL_BARANGAYS_SUCCESS = 'FETCH_ALL_BARANGAYS_SUCCESS';
export const FETCH_ALL_BARANGAYS_FAILED = 'FETCH_ALL_BARANGAYS_FAILED';

export const FETCH_BENEFIT_APPLICATIONS_START =
  'FETCH_BENEFIT_APPLICATIONS_START';
export const FETCH_BENEFIT_APPLICATIONS_SUCCESS =
  'FETCH_BENEFIT_APPLICATIONS_SUCCESS';
export const FETCH_BENEFIT_APPLICATIONS_FAILED =
  'FETCH_BENEFIT_APPLICATIONS_FAILED';

export const FETCH_ALL_MEMBERS_START = 'FETCH_ALL_MEMBERS_START';
export const FETCH_ALL_MEMBERS_SUCCESS = 'FETCH_ALL_MEMBERS_SUCCESS';
export const FETCH_ALL_MEMBERS_FAILED = 'FETCH_ALL_MEMBERS_FAILED';

export const FETCH_ALL_NO_ID_MEMBERS_START = 'FETCH_ALL_NO_ID_MEMBERS_START';
export const FETCH_ALL_NO_ID_MEMBERS_SUCCESS =
  'FETCH_ALL_NO_ID_MEMBERS_SUCCESS';
export const FETCH_ALL_NO_ID_MEMBERS_FAILED = 'FETCH_ALL_NO_ID_MEMBERS_FAILED';

export const FILTER_MEMBERS_START = 'FILTER_MEMBERS_START';
export const FILTER_MEMBERS_SUCCESS = 'FILTER_MEMBERS_SUCCESS';
export const FILTER_MEMBERS_FAILED = 'FILTER_MEMBERS_FAILED';

export const FILTER_PAYMENTS_START = 'FILTER_PAYMENTS_START';
export const FILTER_PAYMENTS_SUCCESS = 'FILTER_PAYMENTS_SUCCESS';
export const FILTER_PAYMENTS_FAILED = 'FILTER_PAYMENTS_FAILED';

export const FETCH_MEMBERS_BY_NAME_START = 'FETCH_MEMBERS_BY_NAME_START';
export const FETCH_MEMBERS_BY_NAME_SUCCESS = 'FETCH_MEMBERS_BY_NAME_SUCCESS';
export const FETCH_MEMBERS_BY_NAME_FAILED = 'FETCH_MEMBERS_BY_NAME_FAILED';

export const FETCH_ALL_OCCUPATIONS_START = 'FETCH_ALL_OCCUPATIONS_START';
export const FETCH_ALL_OCCUPATIONS_SUCCESS = 'FETCH_ALL_OCCUPATIONS_SUCCESS';
export const FETCH_ALL_OCCUPATIONS_FAILED = 'FETCH_ALL_OCCUPATIONS_FAILED';

export const FETCH_MEMBER_PICTURE_START = 'FETCH_MEMBER_PICTURE_START';
export const FETCH_MEMBER_PICTURE_SUCCESS = 'FETCH_MEMBER_PICTURE_SUCCESS';
export const FETCH_MEMBER_PICTURE_FAILED = 'FETCH_MEMBER_PICTURE_FAILED';

export const FETCH_MEMBER_SIGNATURE_START = 'FETCH_MEMBER_SIGNATURE_START';
export const FETCH_MEMBER_SIGNATURE_SUCCESS = 'FETCH_MEMBER_SIGNATURE_SUCCESS';
export const FETCH_MEMBER_SIGNATURE_FAILED = 'FETCH_MEMBER_SIGNATURE_FAILED';

export const FETCH_ONE_ARTICLE_START = 'FETCH_ONE_ARTICLE_START';
export const FETCH_ONE_ARTICLE_SUCCESS = 'FETCH_ONE_ARTICLE_SUCCESS';
export const FETCH_ONE_ARTICLE_FAILED = 'FETCH_ONE_ARTICLE_FAILED';

export const UPDATE_ARTICLE_START = 'UPDATE_ARTICLE_START';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_FAILED = 'UPDATE_ARTICLE_FAILED';

export const UNPUBLISH_ARTICLE_START = 'UNPUBLISH_ARTICLE_START';
export const UNPUBLISH_ARTICLE_SUCCESS = 'UNPUBLISH_ARTICLE_SUCCESS';
export const UNPUBLISH_ARTICLE_FAILED = 'UNPUBLISH_ARTICLE_FAILED';

export const FETCH_ONE_MEMBER_START = 'FETCH_ONE_MEMBER_START';
export const FETCH_ONE_MEMBER_SUCCESS = 'FETCH_ONE_MEMBER_SUCCESS';
export const FETCH_ONE_MEMBER_FAILED = 'FETCH_ONE_MEMBER_FAILED';

export const FETCH_ONE_BENEFIT_APPLICATIONS_START =
  'FETCH_ONE_BENEFIT_APPLICATIONS_START';
export const FETCH_ONE_BENEFIT_APPLICATIONS_SUCCESS =
  'FETCH_ONE_BENEFIT_APPLICATIONS_SUCCESS';
export const FETCH_ONE_BENEFIT_APPLICATIONS_FAILED =
  'FETCH_ONE_BENEFIT_APPLICATIONS_FAILED';

export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILED = 'FETCH_PAYMENTS_FAILED';

export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAILED = 'FETCH_ALL_USERS_FAILED';

export const FETCH_REGISTRATIONS_START = 'FETCH_REGISTRATIONS_START';
export const FETCH_REGISTRATIONS_SUCCESS = 'FETCH_REGISTRATIONS_SUCCESS';
export const FETCH_REGISTRATIONS_FAILED = 'FETCH_REGISTRATIONS_FAILED';

export const UPDATE_ANNOUNCEMENT_START = 'UPDATE_ANNOUNCEMENT_START';
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_FAILED = 'UPDATE_ANNOUNCEMENT_FAILED';

export const UNPUBLISH_ANNOUNCEMENT_START = 'UNPUBLISH_ANNOUNCEMENT_START';
export const UNPUBLISH_ANNOUNCEMENT_SUCCESS = 'UNPUBLISH_ANNOUNCEMENT_SUCCESS';
export const UNPUBLISH_ANNOUNCEMENT_FAILED = 'UNPUBLISH_ANNOUNCEMENT_FAILED';

export const UPDATE_BENEFIT_APPLICATION_START =
  'UPDATE_BENEFIT_APPLICATION_START';
export const UPDATE_BENEFIT_APPLICATION_SUCCESS =
  'UPDATE_BENEFIT_APPLICATION_SUCCESS';
export const UPDATE_BENEFIT_APPLICATION_FAILED =
  'UPDATE_BENEFIT_APPLICATION_FAILED';

export const UPDATE_MEMBER_START = 'UPDATE_MEMBER_START';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILED = 'UPDATE_MEMBER_FAILED';

export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const UPLOAD_PHOTO_START = 'UPLOAD_PHOTO_START';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILED = 'UPLOAD_PHOTO_FAILED';

export const UPLOAD_SIGNATURE_START = 'UPLOAD_SIGNATURE_START';
export const UPLOAD_SIGNATURE_SUCCESS = 'UPLOAD_SIGNATURE_SUCCESS';
export const UPLOAD_SIGNATURE_FAILED = 'UPLOAD_SIGNATURE_FAILED';

export const RESET_USER_PASSWORD_START = 'RESET_USER_PASSWORD_START';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILED = 'RESET_USER_PASSWORD_FAILED';

export const REGISTER_MEMBER_START = 'REGISTER_MEMBER_START';
export const REGISTER_MEMBER_SUCCESS = 'REGISTER_MEMBER_SUCCESS';
export const REGISTER_MEMBER_FAILED = 'REGISTER_MEMBER_FAILED';

export const RESET_ANNOUNCEMENTS = 'RESET_ANNOUNCEMENTS';
export const RESET_ARTICLES = 'RESET_ARTICLES';
export const RESET_ATTACHMENTS = 'RESET_ATTACHMENTS';
export const RESET_BENEFITS = 'RESET_BENEFITS';
export const RESET_MEMBERS = 'RESET_MEMBERS';
export const RESET_PAYMENTS = 'RESET_PAYMENTS';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const RESET_USERS = 'RESET_USERS';

export const COUNT_BARANGAY_STATUS_START = 'COUNT_BARANGAY_STATUS_START';
export const COUNT_BARANGAY_STATUS_SUCCESS = 'COUNT_BARANGAY_STATUS_SUCCESS';
export const COUNT_BARANGAY_STATUS_FAILED = 'COUNT_BARANGAY_STATUS_FAILED';

export const COUNT_AGE_GROUP_START = 'COUNT_AGE_GROUP_START';
export const COUNT_AGE_GROUP_SUCCESS = 'COUNT_AGE_GROUP_SUCCESS';
export const COUNT_AGE_GROUP_FAILED = 'COUNT_AGE_GROUP_FAILED';

import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Navigation from '../UI/Navigation/Index';
import PhotoDropzone from '../Common/PhotoDropzone';
import SignatureDropzone from '../Common/SignatureDropzone';

const Upload = () => {
  const [id, setId] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setId(location.state.id);
  }, [location]);

  return (
    <Fragment>
      <Navigation />
      <div className="container mt-6 w-full">
        <div className="relative overflow-x-auto shadow-md bg-white">
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
            Upload Member Attachments
          </h5>
          <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
            <PhotoDropzone title="Profile Picture" type={1} id={id} />
          </div>
          <div className="flex flex-wrap -mx-auto mb-0 px-6 py-4">
            <SignatureDropzone title="Signature" type={2} id={id} />
          </div>
          <div className="flex flex-wrap -mx-auto mb-0 px-6 py-4">
            <div className="w-full px-1 mb-12 md:mb-0">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 mt-0"
                  onClick={() => navigate('/')}
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Upload;

import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';

const statusOptions = [
  { value: '', name: 'All' },
  { value: 1, name: 'Approved' },
  { value: 2, name: 'For Evaluation' },
  { value: 0, name: 'Disapproved' },
];
const Payments = (props) => {
  const { onFilterPayments, download, loading } = props;

  const [status, setStatus] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const filterPaymentsHandler = (e) => {
    e.preventDefault();
    const data = {
      status: status,
      from: from,
      to: to,
    };
    onFilterPayments(data);
  };
  return (
    <Fragment>
      <form
        onSubmit={filterPaymentsHandler}
        className="flex flex-wrap -mx-auto mb-2 px-6 py-4 shadow-md"
      >
        <div className="w-1/3 px-3 md:mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="status"
          >
            Status
          </label>
          <select
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="barangay"
            name="barangay"
            type="text"
            onChange={(e) => setStatus(e.target.value)}
          >
            {statusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-1/3 px-3 md:mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="from"
          >
            From
          </label>
          <input
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="to"
            name="to"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            value={from}
          />
        </div>
        <div className="w-1/3 px-3 md:mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="to"
          >
            To
          </label>
          <input
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="to"
            name="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            value={to}
          />
        </div>
        <div className="w-full px-1 mb-12 md:mb-0">
          <div className="flex justify-end px-2">
            <Button
              className="h-10 px-6 py-1 text-sm font-medium mr-8 inline-flex items-center"
              type="button"
              disabled={loading}
              onClick={download}
            >
              <svg
                className="h-6 mr-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                ></path>
              </svg>
              {loading ? 'Please wait...' : 'Download'}
            </Button>
            <Button
              className="h-10 px-6 py-1 text-sm font-medium inline-flex items-center"
              type="submit"
              disabled={loading}
            >
              <svg
                className="h-6 mr-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                ></path>
              </svg>
              {loading ? 'Please wait...' : 'Filter'}
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFilterPayments: (data) => dispatch(actions.filterPayments(data)),
  };
};

export default connect(null, mapDispatchToProps)(Payments);

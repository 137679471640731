import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  attachments: null,
  photoCreated: false,
  signatureCreated: false,
  picture: null,
  signature: null,
  error: null,
  photoLoading: false,
  photoProgress: 0,
  signatureLoading: false,
  signatureProgress: 0,
};

const attachments = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_IMAGES_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.DOWNLOAD_IMAGES_SUCCESS:
      return updateObject(state, {
        attachments: action.attachments,
        error: null,
        loading: false,
      });
    case actionTypes.DOWNLOAD_IMAGES_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_MEMBER_PICTURE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_MEMBER_PICTURE_SUCCESS:
      return updateObject(state, {
        picture: action.picture,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_MEMBER_PICTURE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_MEMBER_SIGNATURE_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_MEMBER_SIGNATURE_SUCCESS:
      return updateObject(state, {
        signature: action.signature,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_MEMBER_SIGNATURE_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.UPLOAD_PHOTO_START:
      return updateObject(state, {
        photoProgress: action.progress,
        error: null,
        photoLoading: true,
      });
    case actionTypes.UPLOAD_PHOTO_SUCCESS:
      return updateObject(state, {
        photoCreated: action.created,
        error: null,
        photoLoading: false,
      });
    case actionTypes.UPLOAD_PHOTO_FAILED:
      return updateObject(state, {
        error: action.error,
        photoLoading: false,
      });
    case actionTypes.UPLOAD_SIGNATURE_START:
      return updateObject(state, {
        signatureProgress: action.progress,
        error: null,
        signatureLoading: true,
      });
    case actionTypes.UPLOAD_SIGNATURE_SUCCESS:
      return updateObject(state, {
        signatureCreated: action.created,
        error: null,
        signatureLoading: false,
      });
    case actionTypes.UPLOAD_SIGNATURE_FAILED:
      return updateObject(state, {
        error: action.error,
        signatureLoading: false,
      });
    case actionTypes.RESET_ATTACHMENTS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default attachments;

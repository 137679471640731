import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchBenefitApplicationsStart = () => {
  return {
    type: actionTypes.FETCH_BENEFIT_APPLICATIONS_START,
  };
};

export const fetchBenefitApplicationsSuccess = (applications) => {
  return {
    type: actionTypes.FETCH_BENEFIT_APPLICATIONS_SUCCESS,
    applications: applications,
  };
};

export const fetchBenefitApplicationsFailed = (error) => {
  return {
    type: actionTypes.FETCH_BENEFIT_APPLICATIONS_FAILED,
    error: error,
  };
};

export const fetchBenefitApplications = () => {
  return (dispatch) => {
    dispatch(fetchBenefitApplicationsStart());
    axios
      .get('benefits/applications')
      .then((response) => {
        dispatch(fetchBenefitApplicationsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchBenefitApplicationsFailed(err));
      });
  };
};

export const fetchOneBenefitApplicationStart = () => {
  return {
    type: actionTypes.FETCH_ONE_BENEFIT_APPLICATIONS_START,
  };
};

export const fetchOneBenefitApplicationSuccess = (
  application,
  attachments,
  firebaseId
) => {
  return {
    type: actionTypes.FETCH_ONE_BENEFIT_APPLICATIONS_SUCCESS,
    application: application,
    attachments: attachments,
    firebaseId: firebaseId,
  };
};

export const fetchOneBenefitApplicationFailed = (error) => {
  return {
    type: actionTypes.FETCH_ONE_BENEFIT_APPLICATIONS_FAILED,
    error: error,
  };
};

export const fetchOneBenefitApplication = (data) => {
  return (dispatch) => {
    dispatch(fetchOneBenefitApplicationStart());
    const { id } = data;
    axios
      .get('benefits/find', {
        params: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(
          fetchOneBenefitApplicationSuccess(
            response.data.data,
            response.data.attachments,
            response.data.firebaseId
          )
        );
      })
      .catch((err) => {
        dispatch(fetchOneBenefitApplicationFailed(err));
      });
  };
};

export const updateBenefitApplicationStart = () => {
  return {
    type: actionTypes.UPDATE_BENEFIT_APPLICATION_START,
  };
};

export const updateBenefitApplicationSuccess = (updated) => {
  return {
    type: actionTypes.UPDATE_BENEFIT_APPLICATION_SUCCESS,
    updated: updated,
  };
};

export const updateBenefitApplicationFailed = (error) => {
  return {
    type: actionTypes.UPDATE_BENEFIT_APPLICATION_FAILED,
    error: error,
  };
};

export const updateBenefitApplication = (data) => {
  return (dispatch) => {
    dispatch(updateBenefitApplicationStart());
    axios
      .patch('benefits/update', data)
      .then((response) => {
        dispatch(updateBenefitApplicationSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(updateBenefitApplicationFailed(err));
      });
  };
};

export const resetBenefits = () => {
  return {
    type: actionTypes.RESET_BENEFITS,
  };
};

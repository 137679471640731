import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchOneMemberStart = () => {
  return {
    type: actionTypes.FETCH_ONE_MEMBER_START,
  };
};

export const fetchOneMemberSuccess = (member) => {
  return {
    type: actionTypes.FETCH_ONE_MEMBER_SUCCESS,
    member: member,
  };
};

export const fetchOneMemberFailed = (error) => {
  return {
    type: actionTypes.FETCH_ONE_MEMBER_FAILED,
    error: error,
  };
};

export const fetchOneMember = (data) => {
  return (dispatch) => {
    dispatch(fetchOneMemberStart());
    const { id } = data;
    axios
      .get('members/find', {
        params: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(fetchOneMemberSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchOneMemberFailed(err));
      });
  };
};

export const fetchAllMembersStart = () => {
  return {
    type: actionTypes.FETCH_ALL_MEMBERS_START,
  };
};

export const fetchAllMembersSuccess = (members) => {
  return {
    type: actionTypes.FETCH_ALL_MEMBERS_SUCCESS,
    members: members,
  };
};

export const fetchAllMembersFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_MEMBERS_FAILED,
    error: error,
  };
};

export const fetchAllMembers = () => {
  return (dispatch) => {
    dispatch(fetchAllMembersStart());
    axios
      .get('members')
      .then((response) => {
        dispatch(fetchAllMembersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAllMembersFailed(err));
      });
  };
};

export const createMemberStart = () => {
  return {
    type: actionTypes.CREATE_MEMBER_START,
  };
};

export const createMemberSuccess = (member) => {
  return {
    type: actionTypes.CREATE_MEMBER_SUCCESS,
    member: member,
  };
};

export const createMemberFailed = (error) => {
  return {
    type: actionTypes.CREATE_MEMBER_FAILED,
    error: error,
  };
};

export const createMember = (data) => {
  return (dispatch) => {
    dispatch(createMemberStart());
    axios
      .post('members/create', data)
      .then((response) => {
        dispatch(createMemberSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(createMemberFailed(err));
      });
  };
};

export const updateMemberStart = () => {
  return {
    type: actionTypes.UPDATE_MEMBER_START,
  };
};

export const updateMemberSuccess = (updated) => {
  return {
    type: actionTypes.UPDATE_MEMBER_SUCCESS,
    updated: updated,
  };
};

export const updateMemberFailed = (error) => {
  return {
    type: actionTypes.UPDATE_MEMBER_FAILED,
    error: error,
  };
};

export const updateMember = (data) => {
  return (dispatch) => {
    dispatch(updateMemberStart());
    axios
      .patch('members/update', data)
      .then((response) => {
        dispatch(updateMemberSuccess(response.data.updated));
      })
      .catch((err) => {
        dispatch(updateMemberFailed(err));
      });
  };
};

export const fetchMembersByNameStart = () => {
  return {
    type: actionTypes.FETCH_MEMBERS_BY_NAME_START,
  };
};

export const fetchMembersByNameSuccess = (members) => {
  return {
    type: actionTypes.FETCH_MEMBERS_BY_NAME_SUCCESS,
    members: members,
  };
};

export const fetchMembersByNameFailed = (error) => {
  return {
    type: actionTypes.FETCH_MEMBERS_BY_NAME_FAILED,
    error: error,
  };
};

export const fetchMembersByName = (data) => {
  return (dispatch) => {
    dispatch(fetchMembersByNameStart());
    const { name } = data;
    axios
      .get('members/find-by-name', {
        params: {
          name: name,
        },
      })
      .then((response) => {
        dispatch(fetchMembersByNameSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchMembersByNameFailed(err));
      });
  };
};

export const filterMembersStart = () => {
  return {
    type: actionTypes.FILTER_MEMBERS_START,
  };
};

export const filterMembersSuccess = (members) => {
  return {
    type: actionTypes.FILTER_MEMBERS_SUCCESS,
    members: members,
  };
};

export const filterMembersFailed = (error) => {
  return {
    type: actionTypes.FILTER_MEMBERS_FAILED,
    error: error,
  };
};

export const filterMembers = (data) => {
  return (dispatch) => {
    dispatch(filterMembersStart());
    axios
      .get('members/filter', {
        params: data,
      })
      .then((response) => {
        dispatch(filterMembersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(filterMembersFailed(err));
      });
  };
};

export const fetchAllNoIDMembersStart = () => {
  return {
    type: actionTypes.FETCH_ALL_NO_ID_MEMBERS_START,
  };
};

export const fetchAllNoIDMembersSuccess = (noIdMembers) => {
  return {
    type: actionTypes.FETCH_ALL_NO_ID_MEMBERS_SUCCESS,
    noIdMembers: noIdMembers,
  };
};

export const fetchAllNoIDMembersFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_NO_ID_MEMBERS_FAILED,
    error: error,
  };
};

export const fetchAllNoIDMembers = (data) => {
  return (dispatch) => {
    dispatch(fetchAllNoIDMembersStart());
    axios
      .get('members/no-id', {
        params: {
          barangay: data,
        },
      })
      .then((response) => {
        dispatch(fetchAllNoIDMembersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAllNoIDMembersFailed(err));
      });
  };
};

export const updateRoleStart = () => {
  return {
    type: actionTypes.UPDATE_ROLE_START,
  };
};

export const updateRoleSuccess = (updated, role) => {
  return {
    type: actionTypes.UPDATE_ROLE_SUCCESS,
    updated: updated,
    role: role,
  };
};

export const updateRoleFailed = (error) => {
  return {
    type: actionTypes.UPDATE_ROLE_FAILED,
    error: error,
  };
};

export const updateRole = (data) => {
  return (dispatch) => {
    dispatch(updateRoleStart());
    axios
      .patch('members/update-role', data)
      .then((response) => {
        dispatch(updateRoleSuccess(response.data.updated, data));
      })
      .catch((err) => {
        dispatch(updateRoleFailed(err));
      });
  };
};
export const resetMembers = () => {
  return {
    type: actionTypes.RESET_MEMBERS,
  };
};

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as moment from 'moment';

const PaymentResponse = () => {
  const location = useLocation().search;

  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    if (location) {
      const pId = new URLSearchParams(location).get('t');
      const amt = new URLSearchParams(location).get('a');
      const sts = new URLSearchParams(location).get('s');
      const dt = new URLSearchParams(location).get('dt');
      setAmount(amt);
      setStatus(sts);
      setPaymentId(pId);
      setDate(dt);
    }
  }, [location]);

  return (
    <div className="flex space-y-0 items-center justify-center h-screen p-2 bg-gray-100">
      <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow w-96">
        <div className="flex flex-col space-y-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-green-600 w-28 h-28"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="flex flex-col space-y-2">
          <h5 className="text-2xl text-center">Payment Done!</h5>
          <span className="text-left font-semibold">Transaction Details</span>
          <p className="text-start">
            Status:{' '}
            {status === 'succeeded' ? (
              <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400">
                {status}
              </span>
            ) : (
              <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  border border-red-400">
                {status}
              </span>
            )}
          </p>
          <p className="text-start">Amount: {amount}</p>
          <p>Date: {moment.unix(date).format('MMMM DD, YYYY HH:SS')}</p>
          <p>Reference: {paymentId}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentResponse;

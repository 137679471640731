import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  payment: null,
  payments: null,
  error: null,
  loading: false,
};

const payments = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PAYMENT_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return updateObject(state, {
        payment: action.payment,
        error: null,
        loading: false,
      });
    case actionTypes.CREATE_PAYMENT_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.EVALUATE_PAYMENT_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.EVALUATE_PAYMENT_SUCCESS:
      let status = 'For Evaluation';
      if (action?.payment?.status === 1) {
        status = 'Approved';
      } else if (action?.payment?.status === 0) {
        status = 'Disapproved';
      }
      return updateObject(state, {
        payment: action.payment,
        payments: state.payments.map((x) =>
          x.id === action.payment.id ? { ...x, status: status } : x
        ),
        error: null,
        loading: false,
      });
    case actionTypes.EVALUATE_PAYMENT_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_PAYMENTS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_PAYMENTS_SUCCESS:
      return updateObject(state, {
        payments: action.payments,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_PAYMENTS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FILTER_PAYMENTS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FILTER_PAYMENTS_SUCCESS:
      return updateObject(state, {
        payments: action.payments,
        error: null,
        loading: false,
      });
    case actionTypes.FILTER_PAYMENTS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.RESET_PAYMENTS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default payments;

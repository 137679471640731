export const formatCSVMemberData = (data) => {
  let formatted = [];
  data.forEach((el) => {
    formatted.push({
      'ID Number': el.mid,
      Lastname: el.lastname,
      Firstname: el.firstname,
      Middlename: el.middlename,
      Birthdate: el.birthdate,
      Barangay: el.barangay,
      'Valid Until': el.validity,
      Status: el.status,
    });
  });
  return formatted;
};

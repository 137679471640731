import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import classNames from 'classnames';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Filter from '../Filters/Payments';
import ExportFile from '../Exports/Index';
import Modal from '../UI/Modal/Index';
import Navigation from '../UI/Navigation/Index';
import Table from '../UI/Tables/Index';

const Index = (props) => {
  const [data, setData] = useState({});
  const [download, setDownload] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [paymentItems, setPaymentItems] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    onFetchPaymentsForApproval,
    onEvaluatePayment,
    onResetPayments,
    loading,
    payments,
    payment,
  } = props;

  useEffect(() => {
    onFetchPaymentsForApproval();
  }, [onFetchPaymentsForApproval]);

  useLayoutEffect(() => {
    if (payment) {
      toast.success('Payment was evaluated!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      if (tableData && tableData.length > 0) {
        const newItem = tableData.filter((obj) => obj.key !== payment.id);
        setTableData(newItem);
      }
    }
    setOpen(false);
  }, [payment, tableData]);

  useEffect(() => {
    if (payments) {
      setPaymentItems(payments);
    }
  }, [payments]);

  const evaluatePaymentHandler = (
    id,
    TN,
    amount,
    memberId,
    lastname,
    firstname,
    middlename,
    barangay,
    paymentOption,
    collector,
    status
  ) => {
    setData({
      id,
      TN,
      amount,
      memberId,
      lastname,
      firstname,
      middlename,
      barangay,
      paymentOption,
      collector,
    });
    console.log(status);
    setOpen(true);
  };

  const downloadItemsHandler = () => {
    setDownload(true);
  };

  const updatePaymentButtonHandler = (status) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${
        status === 1 ? 'Yes, Approve Payment' : 'Yes, Decline Payment'
      }`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onEvaluatePayment({
          id: data.id,
          status: status,
          memberId: data.memberId,
        });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Member Data',
        hideHeader: false,
        columns: [
          {
            Header: 'Transaction No.',
            accessor: 'TN',
            Cell: ({ value, row }) => (
              <Link
                className={classNames(
                  row.original.status === 'Approved' && 'pointer-events-none'
                )}
                onClick={() =>
                  evaluatePaymentHandler(
                    row.original.id,
                    row.original.TN,
                    row.original.amount,
                    row.original.memberId,
                    row.original.lastname,
                    row.original.firstname,
                    row.original.middlename,
                    row.original.barangay,
                    row.original.paymentOption,
                    row.original.collector,
                    row.original.status
                  )
                }
              >
                <b>{value}</b>
              </Link>
            ),
          },
          {
            Header: 'LastName',
            accessor: 'lastname',
          },
          {
            Header: 'FirstName',
            accessor: 'firstname',
          },
          {
            Header: 'MiddleName',
            accessor: 'middlename',
          },
          {
            Header: 'Amount',
            accessor: 'amount',
          },
          {
            Header: 'Mode of Payment',
            accessor: 'paymentOption',
          },
          {
            Header: 'Barangay',
            accessor: 'barangay',
          },
          {
            Header: 'Collector',
            accessor: 'collector',
          },
          {
            Header: 'Date',
            accessor: 'date',
            Cell: ({ value }) => moment(value).format('MMM DD, YYYY'),
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <span
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 'Approved'
                    ? 'bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800'
                    : value === 'For Evaluation'
                    ? 'bg-blue-100 text-blue-800  dark:bg-blue-200 dark:text-blue-800'
                    : 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800'
                )}
              >
                {value}
              </span>
            ),
          },
        ],
      },
    ],
    [payments]
  );

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      {open && (
        <Modal
          title="Evaluate Payment"
          btnLabel="Approve Payment"
          enter={() => updatePaymentButtonHandler(1)}
          cancel={() => setOpen(false)}
          decline={() => updatePaymentButtonHandler(0)}
        >
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="amount"
          >
            Amount: ₱{data.amount}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="tn"
          >
            Transaction Number: {data.TN}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="paymentOption"
          >
            Payment Option: {data.paymentOption}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="lastname"
          >
            Lastname: {data.lastname}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="firstname"
          >
            Firstname: {data.firstname}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="middlename"
          >
            Middlename: {data.middlename}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="lastname"
          >
            Barangay: {data.barangay}
          </label>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="collector"
          >
            Collector: {data.collector}
          </label>
        </Modal>
      )}
      <div className="container mt-6 w-full">
        <Button
          type="button"
          onClick={() => setHidden(!hidden)}
          className="h-8 mb-2 px-4 py-1 text-sm font-medium"
          disabled={false}
        >
          {hidden ? 'Show Filters' : 'Hide Filters'}
        </Button>
        {!hidden && (
          <Filter loading={loading} download={downloadItemsHandler} />
        )}
        {download && paymentItems.length > 0 && (
          <ExportFile data={paymentItems} name="Payments" />
        )}
        <Table columns={columns} tableData={paymentItems} loading={loading} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    payment: state.payments.payment,
    payments: state.payments.payments,
    loading: state.payments.loading,
    error: state.payments.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchPaymentsForApproval: () => dispatch(actions.fetchPayments()),
    onEvaluatePayment: (data) => dispatch(actions.evaluatePayment(data)),
    onResetPayments: () => dispatch(actions.resetPayments()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

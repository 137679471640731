import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
  };
};

export const authFailed = (error) => {
  return {
    type: actionTypes.AUTH_FAILED,
    error: error,
  };
};

export const logout = () => {
  const token = localStorage.getItem('token');
  if (token) {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_in');
  }
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (data) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post('users/admin-signin', data)
      .then((response) => {
        const expires_in = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        localStorage.setItem('expires_in', expires_in);
        localStorage.setItem('token', response.data.token);
        dispatch(authSuccess(response.data.token));
        dispatch(checkAuthTimeout(response.data.expires_in));
        window.location.reload();
      })
      .catch((err) => {
        dispatch(authFailed(err.response.data.message));
      });
  };
};

export const authLocalToken = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(authSuccess(token));
    }
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
      const expires_in = new Date(localStorage.getItem('expires_in'));
      if (expires_in <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout((expires_in.getTime() - new Date().getTime()) / 1000)
        );
      }
    } else {
      dispatch(logout());
    }
  };
};

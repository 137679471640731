import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  occupations: null,
  error: null,
  loading: false,
};

const occupations = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_OCCUPATIONS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_OCCUPATIONS_SUCCESS:
      return updateObject(state, {
        occupations: action.occupations,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_OCCUPATIONS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    default:
      return state;
  }
};

export default occupations;

import React, { Fragment, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';

const Create = (props) => {
  const [age, setAge] = useState(0);
  const [barangay, setBarangay] = useState('');
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [beneFirstname, setBeneFirstname] = useState('');
  const [beneLastname, setBeneLastname] = useState('');
  const [beneMiddlename, setBeneMiddlename] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [civilStatus, setCivilStatus] = useState('SINGLE');
  const [contact, setContact] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [memberType, setMemberType] = useState(0);
  const [middlename, setMiddlename] = useState('');
  const [nickname, setNickname] = useState('');
  const [noOfChild, setNoOfChild] = useState(0);
  const [occupation, setOccupation] = useState('');
  const [skills, setSkills] = useState('');
  const [relationship, setRelationship] = useState('');

  const {
    onRegisterMember,
    onResetRegistration,
    onFetchBarangays,
    barangays,
    member,
    loading,
  } = props;

  useEffect(() => {
    onFetchBarangays();
  }, [onFetchBarangays]);

  useEffect(() => {
    if (barangays) {
      const brs = barangays.map((b) => {
        setBarangay(barangays[0].id);
        return (
          <option key={b.id} value={b.id}>
            {b.name}
          </option>
        );
      });
      setBarangayOptions(brs);
    }
  }, [barangays]);

  useEffect(() => {
    if (member) {
      toast.success('Your Details is Created!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        onClose: () => {
          onResetRegistration();
          setAge(0);
          setBarangay('e05f8724-a798-4942-9108-bfea7dc5eabd');
          setBeneFirstname('');
          setBeneLastname('');
          setBeneMiddlename('');
          setBirthdate('');
          setCivilStatus('SINGLE');
          setContact('');
          setFirstname('');
          setLastname('');
          setMemberType(0);
          setMiddlename('');
          setNickname('');
          setNoOfChild(0);
          setOccupation('');
          setRelationship('');
          setSkills('');
        },
      });
    }
  }, [member, onResetRegistration]);

  const birthdateChangeHandler = (e) => {
    setBirthdate(e.target.value);
    if (moment(e.target.value).isValid()) {
      setAge(moment().diff(e.target.value, 'years'));
    }
  };

  const submitNewMemberHandler = (e) => {
    e.preventDefault();
    const brgy = barangays?.find((b) => b.id === barangay);
    Swal.fire({
      title: 'Register Member?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Register',
      reverseButtons: true,
      html: `
        <div style="text-align: left;">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Lastname: ${lastname}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Firstname: ${firstname}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Middlename: ${middlename}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Nickname: ${nickname}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Birthdate: ${birthdate}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Civil Status: ${civilStatus}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Contact: ${contact}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Occupation: ${occupation}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Skills/Part Time Job: ${skills}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Membership Type: ${Number(memberType) === 1 ? 'Premium' : 'Regular'}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Number of Children: ${Number(noOfChild)}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Barangay: ${brgy?.name}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
            Beneficiary
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Lastname: ${beneLastname}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Firstname: ${beneFirstname}
          </label>
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Middlename: ${beneMiddlename}
          </label>
        </div>
      `,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          lastname: lastname,
          firstname: firstname,
          middlename: middlename,
          nickname: nickname,
          birthdate: birthdate,
          civilStatus: civilStatus,
          contact: contact,
          occupation: occupation,
          skills: skills,
          barangay: barangay,
          bene_lastname: beneLastname,
          bene_firstname: beneFirstname,
          bene_middlename: beneMiddlename,
          bene_relationship: relationship,
          type: Number(memberType),
          noOfChild: noOfChild,
        };
        onRegisterMember(data);
      }
    });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="container mt-24 w-full">
        <form onSubmit={submitNewMemberHandler}>
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
            New Member Registration
          </h5>
          <div className="relative overflow-x-auto shadow-md bg-white">
            <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="lastname"
                >
                  Lastname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="lastname"
                >
                  Firstname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  onChange={(e) => setFirstname(e.target.value)}
                  value={firstname}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="middlename"
                >
                  Middlename
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="middlename"
                  name="middlename"
                  type="text"
                  onChange={(e) => setMiddlename(e.target.value)}
                  value={middlename}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="nickname"
                >
                  Nickname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="nickname"
                  name="nickname"
                  type="text"
                  onChange={(e) => setNickname(e.target.value)}
                  value={nickname}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="birthdate"
                >
                  Birthdate
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="birthdate"
                  name="birthdate"
                  type="date"
                  onChange={birthdateChangeHandler}
                  value={birthdate}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="birthdate"
                >
                  Age
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="age"
                  name="age"
                  type="number"
                  value={age}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="civilStatus"
                >
                  Civil Status
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="civilStatus"
                  name="civilStatus"
                  type="text"
                  onChange={(e) => setCivilStatus(e.target.value)}
                >
                  <option key="1" value="SINGLE">
                    SINGLE
                  </option>
                  <option key="2" value="MARRIED">
                    MARRIED
                  </option>
                  <option key="3" value="WIDOWED">
                    WIDOWED
                  </option>
                  <option key="4" value="LEGALLY SEPERATED">
                    LEGALLY SEPERATED
                  </option>
                  <option key="5" value="LIVE IN">
                    LIVE IN
                  </option>
                </select>
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="contact"
                >
                  Contact
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="contact"
                  name="contact"
                  type="text"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="occupation"
                >
                  Occupation
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="occupation"
                  name="occupation"
                  type="text"
                  onChange={(e) => setOccupation(e.target.value)}
                  value={occupation}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="skills"
                >
                  Skills/ Part Time Job
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="skills"
                  name="skills"
                  type="text"
                  onChange={(e) => setSkills(e.target.value)}
                  value={skills}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="barangay"
                >
                  Barangay
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="barangay"
                  name="barangay"
                  type="text"
                  onChange={(e) => setBarangay(e.target.value)}
                  value={barangay}
                >
                  {barangayOptions}
                </select>
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="memberType"
                >
                  Membership type
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="memberType"
                  name="memberType"
                  type="text"
                  onChange={(e) => setMemberType(e.target.value)}
                  value={memberType}
                >
                  <option
                    key="0"
                    value={0}
                    selected={memberType === 0 ? true : false}
                  >
                    Regular
                  </option>
                  <option
                    key="1"
                    value={1}
                    selected={memberType === 1 ? true : false}
                  >
                    Premium
                  </option>
                </select>
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="noOfChild"
                >
                  Number of Children
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="noOfChild"
                  name="noOfChild"
                  type="number"
                  onChange={(e) => setNoOfChild(e.target.value)}
                  value={noOfChild}
                />
              </div>
            </div>
            <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-0 md:mb-0 text-2xl font-bold">
              Beneficiary Information
            </h5>
            <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="beneLastname"
                >
                  Lastname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="beneLastname"
                  name="beneLastname"
                  type="text"
                  onChange={(e) => setBeneLastname(e.target.value)}
                  value={beneLastname}
                  required
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="beneFirstname"
                >
                  Firstname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="beneFirstname"
                  name="beneFirstname"
                  type="text"
                  onChange={(e) => setBeneFirstname(e.target.value)}
                  value={beneFirstname}
                  required
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="beneMiddlename"
                >
                  Middlename
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="beneMiddlename"
                  name="beneMiddlename"
                  type="text"
                  onChange={(e) => setBeneMiddlename(e.target.value)}
                  value={beneMiddlename}
                />
              </div>
              <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="relationship"
                >
                  Relationship
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="relationship"
                  name="relationship"
                  type="text"
                  onChange={(e) => setRelationship(e.target.value)}
                  value={relationship}
                />
              </div>
              <div className="w-full px-1 mb-12 md:mb-0">
                <div className="flex justify-end">
                  <Button type="submit" className="mt-6" disabled={loading}>
                    {loading ? 'Please Wait...' : 'Register'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    barangays: state.barangays.barangays,
    member: state.registrations.data,
    loading: state.registrations.loading,
    error: state.registrations.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterMember: (data) => dispatch(actions.registerMember(data)),
    onFetchBarangays: () => dispatch(actions.fetchAllBarangays()),
    onResetRegistration: () => dispatch(actions.resetRegistration()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);

import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const Create = (props) => {
  const [content, setContent] = useState('');

  const navigate = useNavigate();

  const { onCreateAnnouncement, onResetAnnouncements, announcement } = props;

  useEffect(() => {
    if (announcement) {
      toast.success('Announcement is Created!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => {
        const path = {
          pathname: '/announcements',
        };
        navigate(path);
      }, 2000);
      return () => {
        onResetAnnouncements();
      };
    }
  }, [announcement, onResetAnnouncements, navigate]);

  const submitAnnouncementHandler = (e) => {
    e.preventDefault();
    onCreateAnnouncement({ content: content });
  };
  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <form onSubmit={submitAnnouncementHandler}>
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
            Create Announcement
          </h5>
          <div className="relative overflow-x-auto shadow-md bg-white">
            <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
              <div className="w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="content"
                >
                  Content
                </label>
                <textarea
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="content"
                  name="content"
                  type="text"
                  onChange={(e) => setContent(e.target.value)}
                  value={content}
                  required
                />
              </div>
              <div className="w-full px-1 mb-12 md:mb-0">
                <div className="flex justify-end">
                  <Button type="submit" className="mt-2">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    announcement: state.announcements.announcement,
    loading: state.announcements.loading,
    error: state.announcements.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateAnnouncement: (data) => dispatch(actions.createAnnouncement(data)),
    onResetAnnouncements: () => dispatch(actions.resetAnnouncements()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);

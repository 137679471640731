import React from 'react';
import ReactExport from 'react-export-excel';
import * as moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Index = ({ data, name }) => {
  let header = '';
  if (data.length > 0) {
    header = Object.keys(data[0]).map((m) => {
      return <ExcelColumn label={m} value={m} />;
    });
  }
  return (
    <ExcelFile
      hideElement
      filename={name.replace(/ +/g, '_') + moment().format('MMDDYYYY')}
    >
      <ExcelSheet data={data} name={name}>
        {header}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Index;

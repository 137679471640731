import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const approveRegistrationStart = () => {
  return {
    type: actionTypes.APPROVE_REGISTRATION_START,
  };
};

export const approveRegistrationSuccess = (approved, { id }) => {
  return {
    type: actionTypes.APPROVE_REGISTRATION_SUCCESS,
    approved: approved,
    id: id,
  };
};

export const approveRegistrationFailed = (error) => {
  return {
    type: actionTypes.APPROVE_REGISTRATION_FAILED,
    error: error,
  };
};

export const approveRegistration = (id) => {
  return (dispatch) => {
    dispatch(approveRegistrationStart());
    axios
      .patch('registrations/approve', id)
      .then((response) => {
        dispatch(approveRegistrationSuccess(response.data.approved, id));
      })
      .catch((err) => {
        dispatch(approveRegistrationFailed(err));
      });
  };
};

export const declineRegistrationStart = () => {
  return {
    type: actionTypes.DECLINE_REGISTRATION_START,
  };
};

export const declineRegistrationSuccess = (declined, { id }) => {
  return {
    type: actionTypes.DECLINE_REGISTRATION_SUCCESS,
    declined: declined,
    id: id,
  };
};

export const declineRegistrationFailed = (error) => {
  return {
    type: actionTypes.DECLINE_REGISTRATION_FAILED,
    error: error,
  };
};

export const declineRegistration = (id) => {
  return (dispatch) => {
    dispatch(declineRegistrationStart());
    axios
      .delete('registrations/decline', { data: id })
      .then((response) => {
        dispatch(declineRegistrationSuccess(response.data.declined, id));
      })
      .catch((err) => {
        dispatch(declineRegistrationFailed(err));
      });
  };
};

export const fetchRegistrationsStart = () => {
  return {
    type: actionTypes.FETCH_REGISTRATIONS_START,
  };
};

export const fetchRegistrationsSuccess = (registrations) => {
  return {
    type: actionTypes.FETCH_REGISTRATIONS_SUCCESS,
    registrations: registrations,
  };
};

export const fetchRegistrationsFailed = (error) => {
  return {
    type: actionTypes.FETCH_REGISTRATIONS_FAILED,
    error: error,
  };
};

export const fetchRegistrations = () => {
  return (dispatch) => {
    dispatch(fetchRegistrationsStart());
    axios
      .get('registrations')
      .then((response) => {
        dispatch(fetchRegistrationsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchRegistrationsFailed(err));
      });
  };
};

export const registerMemberStart = () => {
  return {
    type: actionTypes.REGISTER_MEMBER_START,
  };
};

export const registerMemberSuccess = (registration) => {
  return {
    type: actionTypes.REGISTER_MEMBER_SUCCESS,
    registration: registration,
  };
};

export const registerMemberFailed = (error) => {
  return {
    type: actionTypes.REGISTER_MEMBER_FAILED,
    error: error,
  };
};

export const registerMember = (data) => {
  return (dispatch) => {
    dispatch(registerMemberStart());
    axios
      .post('registrations/create', data)
      .then((response) => {
        dispatch(registerMemberSuccess(response.data));
      })
      .catch((err) => {
        dispatch(registerMemberFailed(err));
      });
  };
};

export const resetRegistration = () => {
  return {
    type: actionTypes.RESET_REGISTRATION,
  };
};

import axios from 'axios';
const token = localStorage.getItem('token');
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

export default instance;

import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const Edit = (props) => {
  const [id, setId] = useState('');
  const [contentId, setContentId] = useState('');
  const [announcementContent, setAnnouncmentContent] = useState('');
  const [datePosted, setDatePosted] = useState('');
  const [content, setContent] = useState('');

  const {
    onFetchOneAnnouncement,
    onUpdateAnnouncement,
    onUnpublishAnnnouncement,
    onResetAnnouncements,
    announcement,
    updated,
    loading,
  } = props;

  const location = useLocation().search;
  let navigate = useNavigate();

  useEffect(() => {
    if (announcement) {
      setContentId(announcement.id);
      setAnnouncmentContent(announcement.content);
      setDatePosted(announcement.createdAt);
    }
  }, [announcement]);

  useEffect(() => {
    if (id) {
      onFetchOneAnnouncement({ id: id });
    }
  }, [onFetchOneAnnouncement, id]);

  useEffect(() => {
    if (location) {
      const param = new URLSearchParams(location).get('id');
      setId(param);
    }
  }, [location]);

  useEffect(() => {
    if (updated) {
      toast.success('Changes Saved!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      onResetAnnouncements();
      setTimeout(() => {
        navigate('/announcements');
      }, 2000);
    }
  }, [updated, onResetAnnouncements, navigate]);

  const saveButtonHandler = (id) => {
    Swal.fire({
      title: 'Are you sure to save the changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save changes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
          content: announcementContent,
        };
        onUpdateAnnouncement(data);
      }
    });
  };

  const deleteButtonHandler = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this article?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete article',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
        };
        onUnpublishAnnnouncement(data);
      }
    });
  };

  const handleTextContentChange = (event) => {
    setAnnouncmentContent(event.target.value);
  };

  useEffect(() => {
    if (announcementContent) {
      const data = (
        <div className="w-full ">
          <h5 className="md:w-1/2 mt-5 mb-6 md:mb-0 text-lg font-bold">
            Date Posted: {moment(datePosted).format('MMM DD, YYYY')}
          </h5>
          <textarea
            className="p-3 font-normal w-full bg-slate-200 rounded"
            onChange={handleTextContentChange}
          >
            {announcementContent}
          </textarea>
          <div className="btn-group mt-4 sm:flex sm:flex-row-reverse sm:px-6">
            <Button
              className="px-4"
              onClick={() => saveButtonHandler(contentId)}
              disabled={Object.keys(announcementContent).length < 0 || loading}
            >
              Save changes
            </Button>
            <Button
              className="mr-4 px-4"
              onClick={() => deleteButtonHandler(id)}
              disabled={Object.keys(announcementContent).length < 0 || loading}
            >
              Delete
            </Button>
          </div>
        </div>
      );
      setContent(data);
    }
  }, [announcementContent]);

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">{content}</div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    announcement: state.announcements.announcement,
    updated: state.announcements.updated,
    loading: state.announcements.loading,
    error: state.announcements.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchOneAnnouncement: (data) =>
      dispatch(actions.fetchOneAnnouncement(data)),
    onUpdateAnnouncement: (data) => dispatch(actions.updateAnnouncement(data)),
    onUnpublishAnnnouncement: (data) =>
      dispatch(actions.unpublishAnnouncement(data)),
    onResetAnnouncements: () => dispatch(actions.resetAnnouncements()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

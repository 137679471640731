import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  totalMembers: 0,
  memberStatus: null,
  barangayStatusCount: {},
  ageGroupCount: {},
  memberStatusCount: {},
  error: null,
  loading: false,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COUNT_ALL_MEMBERS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.COUNT_ALL_MEMBERS_SUCCESS:
      return updateObject(state, {
        totalMembers: action.totalMembers,
        error: null,
        loading: false,
      });
    case actionTypes.COUNT_ALL_MEMBERS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.COUNT_MEMBER_STATUS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.COUNT_MEMBER_STATUS_SUCCESS:
      return updateObject(state, {
        memberStatus: action.memberStatus,
        error: null,
        loading: false,
      });
    case actionTypes.COUNT_MEMBER_STATUS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.COUNT_BARANGAY_STATUS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });

    case actionTypes.COUNT_BARANGAY_STATUS_SUCCESS:
      return updateObject(state, {
        barangayStatusCount: action.barangayStatusCount,
        error: null,
        loading: false,
      });
    case actionTypes.COUNT_BARANGAY_STATUS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.COUNT_AGE_GROUP_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });

    case actionTypes.COUNT_AGE_GROUP_SUCCESS:
      return updateObject(state, {
        ageGroupCount: action.ageGroupCount,
        error: null,
        loading: false,
      });
    case actionTypes.COUNT_AGE_GROUP_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    default:
      return state;
  }
};

export default dashboard;

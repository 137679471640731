export const getFileType = (fileType) => {
  let type = '';
  if (fileType === 'image/jpeg') {
    type = 'jpg';
  }
  if (fileType === 'image/png') {
    type = 'png';
  }
  return type;
};

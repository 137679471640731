import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { some, isEmpty, isString } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';

import * as actions from '../../store/actions/index';
import { formatCSVMemberData } from '../../helpers';

import Button from '../UI/Buttons/Index';
import Filter from '../Filters/Index';
import FilterExcel from '../Filters/Excel';
import ExportFile from '../Exports/Index';
import Modal from '../UI/Modal/Index';
import Navigation from '../UI/Navigation/Index';
import Table from '../UI/Tables/Index';

const Index = (props) => {
  const [barangay, setBarangay] = useState('');
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [download, setDownload] = useState(false);
  const [downloadNoIdMembers, setDownloadNoIdMembers] = useState(false);
  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);
  const [openNoIdModal, setOpenNoIdModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [memberItems, setMemberItems] = useState([]);
  const [role, setRole] = useState('');

  const {
    onFetchAllMembers,
    onFilterMembers,
    onFetchAllMembersWithoutId,
    onResetMembers,
    onUpdateRole,
    barangays,
    members,
    noIdMembers,
    loading,
  } = props;

  const navigate = useNavigate();
  const filterRef = useRef(null);

  useEffect(() => {
    onFetchAllMembers();
  }, [onFetchAllMembers]);

  useEffect(() => {
    if (members) {
      setMemberItems(members);
      return () => {
        setDownload(false);
      };
    }
  }, [members, download]);

  useEffect(() => {
    if (noIdMembers) {
      setTimeout(() => {
        setDownloadNoIdMembers(true);
      }, 1000);
    }
    return () => {
      setDownloadNoIdMembers(false);
      setOpenNoIdModal(false);
    };
  }, [noIdMembers]);

  useEffect(() => {
    if (barangays) {
      const brs = barangays.map((b) => {
        return (
          <option key={b.id} value={b.id}>
            {b.name}
          </option>
        );
      });
      setBarangayOptions(brs);
    }
  }, [barangays]);

  const addNewButtonHandler = () => {
    onResetMembers();
    return navigate('/members/create');
  };

  const openExcelHandler = () => {
    setOpen(true);
    setDownloadNoIdMembers(false);
  };

  const openIdExcelHandler = () => {
    setOpenNoIdModal(true);
  };

  const downloadExcelHandler = () => {
    const data = filterRef.current;
    const hasFilter = some(data, (value) => {
      return isString(value) && !isEmpty(value);
    });

    if (hasFilter) {
      onFilterMembers(data);
    }

    if (memberItems.length > 0 && !loading) {
      setTimeout(() => {
        setDownload(true);
        setOpen(false);
      }, 1000);
    }
  };

  const openRoleModalHandler = (role, id) => {
    setId(id);
    setRole(role);
    setOpenRoleModal(true);
  };

  const updateRoleHandler = useCallback(() => {
    const data = {
      id: id,
      role: role,
    };
    onUpdateRole(data);
    setTimeout(() => {
      setOpenRoleModal(false);
      toast.success('Role is Updated!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }, 100);
  }, [role, id]);

  const columns = useMemo(
    () => [
      {
        Header: 'Member Data',
        hideHeader: false,
        columns: [
          {
            Header: 'ID',
            accessor: 'mid',
            Cell: ({ value, row }) => (
              <Link
                to={{
                  pathname: '/members/view',
                  search: `?id=${row.original.id}`,
                }}
                state={{ id: row.original.id }}
              >
                <b>{value}</b>
              </Link>
            ),
          },
          {
            Header: 'LastName',
            accessor: 'lastname',
          },
          {
            Header: 'FirstName',
            accessor: 'firstname',
          },
          {
            Header: 'MiddleName',
            accessor: 'middlename',
          },
          {
            Header: 'Birthdate',
            accessor: 'birthdate',
          },
          {
            Header: 'ID Status',
            accessor: 'idStatus',
          },
          {
            Header: 'Barangay',
            accessor: 'barangay',
          },
          {
            Header: 'Valid Until',
            accessor: 'validity',
          },
          {
            Header: 'Type',
            accessor: 'type',
          },
          {
            Header: 'Role',
            accessor: 'role',
            Cell: ({ value, row }) => (
              <span
                onClick={() => openRoleModalHandler(value, row.original.id)}
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 'PRESIDENT'
                    ? 'bg-blue-100 text-blue-800  dark:bg-blue-200 dark:text-blue-800'
                    : 'bg-gray-100 text-gray-800 dark:bg-gray-200 dark:text-gray-800'
                )}
              >
                {value}
              </span>
            ),
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <span
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 'Active'
                    ? 'bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800'
                    : 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800'
                )}
              >
                {value}
              </span>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      {open && (
        <Modal
          title="Filter Data"
          btnLabel="Download"
          cancel={() => setOpen(false)}
          enter={() => downloadExcelHandler()}
          loading={loading}
        >
          <FilterExcel filterRef={filterRef} />
        </Modal>
      )}
      {openRoleModal && (
        <Modal
          title="Edit Role"
          btnLabel="Update"
          cancel={() => setOpenRoleModal(false)}
          enter={() => updateRoleHandler()}
          loading={loading}
        >
          <select
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="civilStatus"
            name="civilStatus"
            type="text"
            onChange={(e) => setRole(e.target.value)}
          >
            <option
              key="1"
              value="PRESIDENT"
              selected={role === 'PRESIDENT' ? true : false}
            >
              PRESIDENT
            </option>
            <option
              key="2"
              value="MEMBER"
              selected={role === 'MEMBER' ? true : false}
            >
              MEMBER
            </option>
          </select>
        </Modal>
      )}
      {openNoIdModal && (
        <Modal
          title="Filter Data"
          btnLabel="Download"
          cancel={() => setOpenNoIdModal(false)}
          enter={() => onFetchAllMembersWithoutId(barangay)}
          loading={loading}
        >
          <select
            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="barangay"
            name="barangay"
            type="text"
            onChange={(e) => setBarangay(e.target.value)}
          >
            <option value="">All</option>
            {barangayOptions}
          </select>
        </Modal>
      )}
      {download && memberItems.length > 0 && (
        <ExportFile data={formatCSVMemberData(memberItems)} name="Members" />
      )}
      {downloadNoIdMembers && noIdMembers && noIdMembers.length > 0 && (
        <ExportFile data={noIdMembers} name="ID For Printing" />
      )}
      <div className="container mt-6 w-full">
        <Button onClick={addNewButtonHandler} className="mb-4">
          Add New
        </Button>
        <Filter />
        <Button
          onClick={openExcelHandler}
          className="h-8 px-4 py-1 text-sm font-medium mx-4 mb-2"
          disabled={false}
        >
          Generate Excel File
        </Button>
        <Button
          onClick={openIdExcelHandler}
          className="h-8 px-4 py-1 text-sm font-medium mb-2"
          disabled={loading}
        >
          {loading ? 'Please Wait...' : 'Generate ID For Printing'}
        </Button>
        <Table columns={columns} tableData={memberItems} loading={loading} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    barangays: state.barangays.barangays,
    members: state.members.members,
    noIdMembers: state.members.noIdMembers,
    updated: state.members.updated,
    loading: state.members.loading,
    error: state.members.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchAllMembers: (data) => dispatch(actions.fetchAllMembers(data)),
    onFetchAllMembersWithoutId: (data) =>
      dispatch(actions.fetchAllNoIDMembers(data)),
    onFilterMembers: (data) => dispatch(actions.filterMembers(data)),
    onResetMembers: () => dispatch(actions.resetMembers()),
    onUpdateRole: (data) => dispatch(actions.updateRole(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

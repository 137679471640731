import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

import * as actions from '../../store/actions/index';

import Navigation from '../UI/Navigation/Index';

const View = (props) => {
  const [app, setApp] = useState({});
  const [atts, setAtts] = useState('');
  const [fireId, setFireId] = useState('');
  const [id, setId] = useState('');

  const {
    onFetchBenefitApplication,
    onUpdateApplicationStatus,
    onResetBenefits,
    application,
    attachments,
    firebaseId,
    updated,
  } = props;

  const location = useLocation().search;
  let navigate = useNavigate();

  useEffect(() => {
    if (location) {
      const param = new URLSearchParams(location).get('id');
      setId(param);
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      onFetchBenefitApplication({ id: id });
    }
  }, [onFetchBenefitApplication, id]);

  useEffect(() => {
    if (application) {
      setApp(application);
    }
  }, [application]);

  useEffect(() => {
    if (attachments) {
      const attachmentData = attachments.map((a, i) => {
        return (
          <div key={i} className="w-full px-3 mb-12 md:mb-12">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-0 sm:grid-cols-1">
              <img key={a.type} src={a.attachment} alt={i} />
            </div>
          </div>
        );
      });
      setAtts(attachmentData);
    }
  }, [attachments]);

  useEffect(() => {
    if (firebaseId) {
      setFireId(firebaseId.firebaseId);
    }
  }, [firebaseId]);

  useEffect(() => {
    if (updated) {
      toast.success('Benefit application was evaluated!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      onResetBenefits();
      setTimeout(() => {
        navigate('/benefits');
      }, 2000);
    }
  }, [updated, onResetBenefits, navigate]);

  const approveButtonHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve Application',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
          status: 'APPROVED',
          firebaseId: fireId,
          name: app?.sys_benefit?.name,
          memberId: app?.memberId,
        };
        onUpdateApplicationStatus(data);
      }
    });
  };

  const rejectButtonHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject Application',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
          status: 'REJECTED',
          firebaseId: fireId,
          name: app?.sys_benefit?.name,
          memberId: app?.memberId,
        };
        onUpdateApplicationStatus(data);
      }
    });
  };

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <div className="relative overflow-x-auto shadow-md bg-white">
          <div className="md:w-1/2 w-full px-3 mx:w-full">
            <h5 className="md:w-1/2 mt-5 mb-6 md:mb-0 text-2xl font-bold">
              Application Information
            </h5>
          </div>
          <div className="md:w-1/2 w-full px-3 mx:w-full mt-5">
            {Object.keys(app).length > 0 && (
              <Fragment>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="amount"
                >
                  Name:{' '}
                  {app.tbl_member.lastname +
                    ', ' +
                    app.tbl_member.firstname +
                    ' ' +
                    app.tbl_member.middlename}
                </label>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="amount"
                >
                  Applied Benefit: {app.sys_benefit.name}
                </label>
                <h6 className="md:w-1/2 mt-5 mb-6 md:mb-0 text-2xl font-bold">
                  Attachments
                </h6>
                {atts}
                <div className="btn-group bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-400"
                    onClick={() => approveButtonHandler(id)}
                    disabled={
                      (Object.keys(app).length > 0 &&
                        app.status !== 'FOR EVALUATION') ||
                      atts.length <= 0
                    }
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-400"
                    onClick={() => rejectButtonHandler(id)}
                    disabled={
                      Object.keys(app).length > 0 &&
                      app.status !== 'FOR EVALUATION'
                    }
                  >
                    Reject
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    application: state.benefits.application,
    attachments: state.benefits.attachments,
    firebaseId: state.benefits.firebaseId,
    updated: state.benefits.updated,
    loading: state.benefits.loading,
    error: state.benefits.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchBenefitApplication: (data) =>
      dispatch(actions.fetchOneBenefitApplication(data)),
    onUpdateApplicationStatus: (data) =>
      dispatch(actions.updateBenefitApplication(data)),
    onResetBenefits: () => dispatch(actions.resetBenefits()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

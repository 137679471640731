import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';
import Table from '../UI/Tables/Index';

const Index = (props) => {
  const { onFetchAllUsers, onResetUsers, users, loading } = props;
  const navigate = useNavigate();

  const [userItems, setUserItems] = useState([]);

  useEffect(() => {
    onFetchAllUsers();
  }, [onFetchAllUsers]);

  useEffect(() => {
    if (users) {
      setUserItems(users);
    }
  }, [users]);

  const columns = useMemo(
    () => [
      {
        Header: 'Member Data',
        hideHeader: false,
        columns: [
          {
            Header: 'Username',
            accessor: 'username',
            // Cell: ({ value, row }) => (
            //   <Link
            //     to={{
            //       pathname: '/members/view',
            //       search: `?id=${row.original.id}`,
            //       state: { id: row.original.id },
            //     }}
            //   >
            //     <b>{value}</b>
            //   </Link>
            // ),
          },
          {
            Header: 'LastName',
            accessor: 'lastname',
          },
          {
            Header: 'FirstName',
            accessor: 'firstname',
          },
          {
            Header: 'MiddleName',
            accessor: 'middlename',
          },
          {
            Header: 'Role',
            accessor: 'role',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <span
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 'Active'
                    ? 'bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800'
                    : 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800'
                )}
              >
                {value}
              </span>
            ),
          },
          {
            Header: 'Action',
            accessor: 'id',
            Cell: ({ value, row }) => (
              <Link
                to={{
                  pathname: '/user/edit',
                  search: `?id=${row.original.id}`,
                }}
                state={{ role: row.original.role, status: row.original.status }}
              >
                <div className="flex items-center px-1">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    ></path>
                  </svg>
                  <b>Edit</b>
                </div>
              </Link>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      <Navigation />
      <div className="container mt-6 w-full">
        <Button
          className="mb-2"
          onClick={() => {
            navigate('/user/create');
            onResetUsers();
          }}
          disabled={loading}
        >
          {loading ? 'Please wait...' : 'Add New'}
        </Button>
        <Table tableData={userItems} columns={columns} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users.users,
    loading: state.users.loading,
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchAllUsers: () => dispatch(actions.fetchAllUsers()),
    onResetUsers: () => dispatch(actions.resetUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchAllBarangaysStart = () => {
  return {
    type: actionTypes.FETCH_ALL_BARANGAYS_START,
  };
};

export const fetchAllBarangaysSuccess = (barangays) => {
  return {
    type: actionTypes.FETCH_ALL_BARANGAYS_SUCCESS,
    barangays: barangays,
  };
};

export const fetchAllBarangaysFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_BARANGAYS_FAILED,
    error: error,
  };
};

export const fetchAllBarangays = () => {
  return (dispatch) => {
    dispatch(fetchAllBarangaysStart());
    axios
      .get('barangays')
      .then((response) => {
        dispatch(fetchAllBarangaysSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAllBarangaysFailed(err));
      });
  };
};

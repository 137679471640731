import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  announcement: null,
  announcements: null,
  updated: false,
  error: null,
  loading: false,
};

const announcements = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ANNOUNCEMENT_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.CREATE_ANNOUNCEMENT_SUCCESS:
      return updateObject(state, {
        announcement: action.announcement,
        error: null,
        loading: false,
      });
    case actionTypes.CREATE_ANNOUNCEMENT_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });
    case actionTypes.FETCH_ALL_ANNOUNCEMENTS_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ALL_ANNOUNCEMENTS_SUCCESS:
      return updateObject(state, {
        announcements: action.announcements,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ALL_ANNOUNCEMENTS_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.FETCH_ONE_ANNOUNCEMENT_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.FETCH_ONE_ANNOUNCEMENT_SUCCESS:
      return updateObject(state, {
        announcement: action.announcement,
        error: null,
        loading: false,
      });
    case actionTypes.FETCH_ONE_ANNOUNCEMENT_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.UPDATE_ANNOUNCEMENT_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UPDATE_ANNOUNCEMENT_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.UNPUBLISH_ANNOUNCEMENT_START:
      return updateObject(state, {
        error: null,
        loading: true,
      });
    case actionTypes.UNPUBLISH_ANNOUNCEMENT_SUCCESS:
      return updateObject(state, {
        updated: action.updated,
        error: null,
        loading: false,
      });
    case actionTypes.UNPUBLISH_ANNOUNCEMENT_FAILED:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.RESET_ANNOUNCEMENTS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default announcements;

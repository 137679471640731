import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import Button from '../UI/Buttons/Index';
import Navigation from '../UI/Navigation/Index';

const Create = (props) => {
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [contact, setContact] = useState('');
  const [occupation, setOccupation] = useState('');
  const [occupationOptions, setOccupationOptions] = useState('');
  const [barangay, setBarangay] = useState('');
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const {
    onCreateUser,
    onFetchBarangays,
    onFetchOccupations,
    barangays,
    occupations,
    user,
    error,
    loading,
  } = props;
  let navigate = useNavigate();

  useEffect(() => {
    onFetchBarangays();
  }, [onFetchBarangays]);

  useEffect(() => {
    onFetchOccupations();
  }, [onFetchOccupations]);

  useEffect(() => {
    if (barangays) {
      const brs = barangays.map((b) => {
        setBarangay(barangays[0].id);
        return (
          <option key={b.id} value={b.id}>
            {b.name}
          </option>
        );
      });
      setBarangayOptions(brs);
    }
  }, [barangays]);

  useEffect(() => {
    if (occupations) {
      const ocs = occupations.map((o) => {
        setOccupation(occupations[0].id);
        return (
          <option key={o.id} value={o.id}>
            {o.name}
          </option>
        );
      });
      setOccupationOptions(ocs);
    }
  }, [occupations]);

  useEffect(() => {
    if (error) {
      toast.error(error.response.data.message, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      toast.success('User is Created!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => {
        navigate('/users');
      }, 2000);
    }
  }, [user, navigate]);

  const submitNewUserHandler = (e) => {
    e.preventDefault();
    const data = {
      lastname,
      firstname,
      middlename,
      birthdate,
      contact,
      occupation,
      barangay,
      username,
      password,
    };
    onCreateUser(data);
  };

  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      <div className="container mt-6 w-full">
        <form onSubmit={submitNewUserHandler}>
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-6 md:mb-0 text-2xl font-bold">
            Add New User
          </h5>
          <div className="relative overflow-x-auto shadow-md bg-white">
            <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4">
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="lastname"
                >
                  Lastname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="lastname"
                >
                  Firstname
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  name="lastname"
                  type="text"
                  onChange={(e) => setFirstname(e.target.value)}
                  value={firstname}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="middlename"
                >
                  Middlename
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="middlename"
                  name="middlename"
                  type="text"
                  onChange={(e) => setMiddlename(e.target.value)}
                  value={middlename}
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="birthdate"
                >
                  Birthdate
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="birthdate"
                  name="birthdate"
                  type="date"
                  onChange={(e) => setBirthdate(e.target.value)}
                  value={birthdate}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="contact"
                >
                  Contact
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="contact"
                  name="contact"
                  type="text"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="occupation"
                >
                  Occupation
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="occupation"
                  name="occupation"
                  type="text"
                  onChange={(e) => setOccupation(e.target.value)}
                >
                  {occupationOptions}
                </select>
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="barangay"
                >
                  Barangay
                </label>
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="barangay"
                  name="barangay"
                  type="text"
                  onChange={(e) => setBarangay(e.target.value)}
                >
                  {barangayOptions}
                </select>
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="username"
                >
                  Username
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="username"
                  name="username"
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                />
              </div>
              <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </div>
              <div className="w-full px-4">
                <div className="flex justify-end">
                  <Button type="submit" className="mt-2" disabled={loading}>
                    {loading ? 'Please Wait...' : 'Save User'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    barangays: state.barangays.barangays,
    user: state.users.user,
    occupations: state.occupations.occupations,
    loading: state.users.loading,
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateUser: (data) => dispatch(actions.createUser(data)),
    onFetchBarangays: () => dispatch(actions.fetchAllBarangays()),
    onFetchOccupations: () => dispatch(actions.fetchAllOccupations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);

import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const createPaymentStart = () => {
  return {
    type: actionTypes.CREATE_PAYMENT_START,
  };
};

export const createPaymentSuccess = (payment) => {
  return {
    type: actionTypes.CREATE_PAYMENT_SUCCESS,
    payment: payment,
  };
};

export const createPaymentFailed = (error) => {
  return {
    type: actionTypes.CREATE_PAYMENT_FAILED,
    error: error,
  };
};

export const createPayment = (data) => {
  return (dispatch) => {
    dispatch(createPaymentStart());
    axios
      .post('payments/create', data)
      .then((response) => {
        dispatch(createPaymentSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(createPaymentFailed(err));
      });
  };
};

export const evaluatePaymentStart = () => {
  return {
    type: actionTypes.EVALUATE_PAYMENT_START,
  };
};

export const evaluatePaymentSuccess = (payment) => {
  return {
    type: actionTypes.EVALUATE_PAYMENT_SUCCESS,
    payment: payment,
  };
};

export const evaluatePaymentFailed = (error) => {
  return {
    type: actionTypes.EVALUATE_PAYMENT_FAILED,
    error: error,
  };
};

export const evaluatePayment = (data) => {
  return (dispatch) => {
    dispatch(evaluatePaymentStart());
    axios
      .patch('payments/evaluate', data)
      .then((response) => {
        dispatch(evaluatePaymentSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(evaluatePaymentFailed(err));
      });
  };
};

export const fetchPaymentsStart = () => {
  return {
    type: actionTypes.FETCH_PAYMENTS_START,
  };
};

export const fetchPaymentsSuccess = (payments) => {
  return {
    type: actionTypes.FETCH_PAYMENTS_SUCCESS,
    payments: payments,
  };
};

export const fetchPaymentsFailed = (error) => {
  return {
    type: actionTypes.FETCH_PAYMENTS_FAILED,
    error: error,
  };
};

export const fetchPayments = () => {
  return (dispatch) => {
    dispatch(fetchPaymentsStart());
    axios
      .get('payments')
      .then((response) => {
        dispatch(fetchPaymentsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentsFailed(err));
      });
  };
};

export const filterPaymentsStart = () => {
  return {
    type: actionTypes.FILTER_PAYMENTS_START,
  };
};

export const filterPaymentsSuccess = (payments) => {
  return {
    type: actionTypes.FILTER_PAYMENTS_SUCCESS,
    payments: payments,
  };
};

export const filterPaymentsFailed = (error) => {
  return {
    type: actionTypes.FILTER_PAYMENTS_FAILED,
    error: error,
  };
};

export const filterPayments = (data) => {
  return (dispatch) => {
    const { status, from, to } = data;
    dispatch(fetchPaymentsStart());
    axios
      .get('payments/filter', {
        params: {
          status: status,
          from: from,
          to: to,
        },
      })
      .then((response) => {
        dispatch(filterPaymentsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(filterPaymentsFailed(err));
      });
  };
};

export const resetPayments = () => {
  return {
    type: actionTypes.RESET_PAYMENTS,
  };
};

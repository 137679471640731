import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchAllOccupationsStart = () => {
  return {
    type: actionTypes.FETCH_ALL_OCCUPATIONS_START,
  };
};

export const fetchAllOccupationsSuccess = (occupations) => {
  return {
    type: actionTypes.FETCH_ALL_OCCUPATIONS_SUCCESS,
    occupations: occupations,
  };
};

export const fetchAllOccupationsFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_OCCUPATIONS_FAILED,
    error: error,
  };
};

export const fetchAllOccupations = () => {
  return (dispatch) => {
    dispatch(fetchAllOccupationsStart());
    axios
      .get('occupations')
      .then((response) => {
        dispatch(fetchAllOccupationsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(fetchAllOccupationsFailed(err));
      });
  };
};
